var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import { buttonTypeConfig, buttonSizeConfig, ButtonTypes, ButtonSizes } from './config';
import { AppIcon } from '../Icon';
import isBoolean from 'lodash/isBoolean';
import AppButton from './AppButton.vue';
var AppButtonSplit = /** @class */ (function (_super) {
    __extends(AppButtonSplit, _super);
    function AppButtonSplit() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isOpen = false;
        return _this;
    }
    Object.defineProperty(AppButtonSplit.prototype, "dynamicClass", {
        get: function () {
            var _a;
            return _a = {},
                _a[buttonTypeConfig[this.type]] = true,
                _a[buttonSizeConfig[this.size]] = true,
                _a.loading = this.loading,
                _a.active = this.active,
                _a['btn-block'] = this.block,
                _a;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppButtonSplit.prototype, "openIcon", {
        get: function () {
            return this.isOpen ? 'angle-up' : 'angle-down';
        },
        enumerable: false,
        configurable: true
    });
    AppButtonSplit.prototype.onClickOption = function (option) {
        if (!option.disabled)
            option.onClick();
        this.toggleSecondary(false);
    };
    AppButtonSplit.prototype.onClick = function () {
        if (!this.disabled) {
            this.$emit('onClick');
            this.toggleSecondary(false);
        }
    };
    AppButtonSplit.prototype.toggleSecondary = function (open) {
        this.isOpen = isBoolean(open) ? open : !this.isOpen;
    };
    AppButtonSplit.prototype.onClickSecondary = function () {
        if (!this.disabled) {
            this.$emit('onClickSecondary');
            this.toggleSecondary(false);
        }
    };
    __decorate([
        Prop()
    ], AppButtonSplit.prototype, "text", void 0);
    __decorate([
        Prop()
    ], AppButtonSplit.prototype, "disabled", void 0);
    __decorate([
        Prop()
    ], AppButtonSplit.prototype, "loading", void 0);
    __decorate([
        Prop()
    ], AppButtonSplit.prototype, "active", void 0);
    __decorate([
        Prop()
    ], AppButtonSplit.prototype, "block", void 0);
    __decorate([
        Prop({ default: ButtonTypes.primary })
    ], AppButtonSplit.prototype, "type", void 0);
    __decorate([
        Prop({ default: ButtonSizes.sm })
    ], AppButtonSplit.prototype, "size", void 0);
    __decorate([
        Prop()
    ], AppButtonSplit.prototype, "appendedIcon", void 0);
    __decorate([
        Prop()
    ], AppButtonSplit.prototype, "prependedIcon", void 0);
    __decorate([
        Prop({ default: function () { return []; } })
    ], AppButtonSplit.prototype, "secondaryOptions", void 0);
    AppButtonSplit = __decorate([
        Component({ components: { AppIcon: AppIcon, AppButton: AppButton } })
    ], AppButtonSplit);
    return AppButtonSplit;
}(Vue));
export default AppButtonSplit;

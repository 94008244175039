var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import VueSignaturePad from 'vue-signature-pad';
import { AppIconButton } from '../Button';
Vue.use(VueSignaturePad);
var AppSignaturePad = /** @class */ (function (_super) {
    __extends(AppSignaturePad, _super);
    function AppSignaturePad() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.signaturePadRefName = 'signaturePadRefName';
        return _this;
    }
    Object.defineProperty(AppSignaturePad.prototype, "options", {
        get: function () {
            return {
                penColor: this.color,
                onBegin: this.onBegin,
                onEnd: this.onEnd,
            };
        },
        enumerable: false,
        configurable: true
    });
    AppSignaturePad.prototype.undo = function () {
        this.$refs[this.signaturePadRefName].undoSignature();
    };
    AppSignaturePad.prototype.onBegin = function () {
        return;
    };
    AppSignaturePad.prototype.onEnd = function () {
        this.input();
        return;
    };
    AppSignaturePad.prototype.input = function () {
        var data = this.$refs[this.signaturePadRefName].saveSignature().data;
        return data;
    };
    AppSignaturePad.prototype.onLockPad = function () {
        if (this.lockPad) {
            this.input();
            this.$refs[this.signaturePadRefName].lockSignaturePad();
        }
        else {
            this.$refs[this.signaturePadRefName].openSignaturePad();
        }
    };
    __decorate([
        Prop()
    ], AppSignaturePad.prototype, "value", void 0);
    __decorate([
        Prop({ default: 'black' })
    ], AppSignaturePad.prototype, "color", void 0);
    __decorate([
        Prop()
    ], AppSignaturePad.prototype, "lockPad", void 0);
    __decorate([
        Prop({ default: true })
    ], AppSignaturePad.prototype, "useUndo", void 0);
    __decorate([
        Emit('onBegin')
    ], AppSignaturePad.prototype, "onBegin", null);
    __decorate([
        Emit('onEnd')
    ], AppSignaturePad.prototype, "onEnd", null);
    __decorate([
        Emit('input')
    ], AppSignaturePad.prototype, "input", null);
    __decorate([
        Watch('lockPad')
    ], AppSignaturePad.prototype, "onLockPad", null);
    AppSignaturePad = __decorate([
        Component({ components: { AppIconButton: AppIconButton } })
    ], AppSignaturePad);
    return AppSignaturePad;
}(Vue));
export default AppSignaturePad;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { fabric } from "fabric";
import isNumber from "lodash/isNumber";
var checkedSupportForLargeImages = false;
export var applyGreyScale = function (canvas, img) {
    var filter = new fabric.Image.filters.Grayscale();
    return applyFilter(canvas, img, filter);
};
export var applyBrightness = function (canvas, img, brightness /*number between -100 and 100 */) {
    var adjusted = brightness / 100;
    var filter = new fabric.Image.filters.Brightness({
        brightness: adjusted,
    });
    return applyFilter(canvas, img, filter);
};
export var applyWhiteBalance = function (canvas, img, whiteBalance) {
    //@ts-ignore
    var whiteBalanceFilter = new fabric.Image.filters.WhiteBalance({
        whiteBalance: whiteBalance,
    });
    return applyFilter(canvas, img, whiteBalanceFilter);
};
export var applyContrast = function (canvas, img, contrast /*number between -100 and 100 */) {
    var adjusted = contrast / 100;
    var filter = new fabric.Image.filters.Contrast({ contrast: adjusted });
    return applyFilter(canvas, img, filter);
};
export var applySaturation = function (canvas, img, saturation /*number between -100 and 100 */) {
    var adjusted = saturation / 100;
    var filter = new fabric.Image.filters.Saturation({ saturation: adjusted });
    return applyFilter(canvas, img, filter);
};
export var applyGamma = function (canvas, img, values /*number between -0.01, 2.2 */) {
    var filtersAsAny = fabric.Image.filters;
    var filter = new filtersAsAny.Gamma({ gamma: __spreadArray([], values, true) });
    return applyFilter(canvas, img, filter);
};
export var removeColor = function (canvas, img, options) {
    var filtersAsAny = fabric.Image.filters;
    var filter = new filtersAsAny.RemoveColor(__assign({}, options));
    return applyFilter(canvas, img, filter);
};
export var updateFilterValue = function (canvas, img, filter) { return __awaiter(void 0, void 0, void 0, function () {
    var filterType, mappedKey, newValue, hasNewValue, iFound;
    var _a;
    return __generator(this, function (_b) {
        filterType = filter.type;
        mappedKey = FilterTypeToValue[filterType];
        newValue = filter[mappedKey];
        hasNewValue = newValue !== undefined;
        iFound = (_a = img.filters) === null || _a === void 0 ? void 0 : _a.findIndex(function (data) { return data[mappedKey]; });
        if (filterType &&
            mappedKey &&
            hasNewValue &&
            isNumber(iFound) &&
            iFound >= 0) {
            //@ts-ignore
            img.filters[iFound][mappedKey] = newValue;
            img.applyFilters();
            canvas.renderAll();
            return [2 /*return*/, canvas];
        }
        return [2 /*return*/, false];
    });
}); };
export var applyFilter = function (canvas, img, filter) { return __awaiter(void 0, void 0, void 0, function () {
    var updated;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                !checkedSupportForLargeImages && addSupportForLargeImages();
                return [4 /*yield*/, updateFilterValue(canvas, img, filter)];
            case 1:
                updated = _b.sent();
                if (updated)
                    return [2 /*return*/, updated];
                // apply filters and re-render canvas when done
                (_a = img.filters) === null || _a === void 0 ? void 0 : _a.push(filter);
                img.applyFilters();
                canvas.renderAll();
                return [2 /*return*/, canvas];
        }
    });
}); };
export var addSupportForLargeImages = function () { return __awaiter(void 0, void 0, void 0, function () {
    var enableGLFiltering, useWebGl, webglBackend_1;
    var _a;
    return __generator(this, function (_b) {
        /*
      Certain hardware can only handle canvas elements at smaller sizes in a canvas2d context
      For example, with canvas2d context the max resolution we can get it is 2048x2048 or 4096x4096 for more modern computers
      */
        if (checkedSupportForLargeImages)
            return [2 /*return*/];
        checkedSupportForLargeImages = true;
        fabric.textureSize = 4096; // set max
        //@ts-ignore
        if (fabric.isWebglSupported())
            fabric.textureSize = 4096; //16384 max achieved with m1 mac
        enableGLFiltering = fabric.enableGLFiltering;
        useWebGl = (_a = fabric.isWebglSupported) === null || _a === void 0 ? void 0 : _a.call(fabric, fabric.textureSize);
        if (enableGLFiltering && useWebGl) {
            webglBackend_1 = new fabric.WebglFilterBackend({
                tileSize: fabric.textureSize,
            });
            fabric.filterBackend = webglBackend_1;
            //@ts-ignore
            fabric.initFilterBackend = function () { return webglBackend_1; };
        }
        else if (fabric.Canvas2dFilterBackend) {
            //@ts-ignore
            fabric.initFilterBackend = function () { return new fabric.Canvas2dFilterBackend(); };
        }
        return [2 /*return*/];
    });
}); };
var FilterTypeToValue = {
    Brightness: "brightness",
    Gamma: "gamma",
    Contrast: "contrast",
    Saturation: "saturation",
    WhiteBalance: "whiteBalance",
    GrayScale: "greyScale",
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { applyBrightness, applyCropImageClipScale, applyGamma, applyLinearVignetteAllEdges, applyWhiteBalance, VignettePosition, } from 'common/imageManipulation';
import { storeModules } from '..';
import { StilettoEditTypes } from './enums';
import { stilettoEditTypeMapToEditHistory } from './helpers';
var methodConfig = (_a = {},
    _a[StilettoEditTypes.editGradient] = function (data) { return performEditGradient(data); },
    _a[StilettoEditTypes.editBrightness] = function (data) { return performEditBrightness(data); },
    _a[StilettoEditTypes.editCrop] = function (data) { return performEditCrop(data); },
    _a[StilettoEditTypes.editEraser] = function (data) { return preferToHandleInController(data); },
    _a[StilettoEditTypes.editGamma] = function (data) { return performEditGamma(data); },
    _a[StilettoEditTypes.editWhiteBalance] = function (data) { return performEditWhiteBalance(data); },
    _a[StilettoEditTypes.reset] = function (data) { return preferToHandleInController(data); },
    _a[StilettoEditTypes.undo] = function (data) { return preferToHandleInController(data); },
    _a[StilettoEditTypes.renderFullResoltion] = function (data) { return preferToHandlInStore(data); },
    _a);
export var stilettoEditsOrder = [
    StilettoEditTypes.editCrop,
    StilettoEditTypes.editWhiteBalance,
    StilettoEditTypes.editBrightness,
    StilettoEditTypes.editEraser,
    StilettoEditTypes.editGradient,
];
export var performImageEditReturnHistory = function (data) { return __awaiter(void 0, void 0, void 0, function () {
    var editType, useDataURLImage, img, newData, selectedMethod, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                editType = data.editType, useDataURLImage = data.useDataURLImage;
                img = data.canvas.backgroundImage;
                newData = __assign(__assign({}, data), { img: img });
                selectedMethod = methodConfig[editType];
                //useDataURLImage is for crop and eraser tooled images
                _a = !useDataURLImage && selectedMethod;
                if (!_a) 
                //useDataURLImage is for crop and eraser tooled images
                return [3 /*break*/, 2];
                return [4 /*yield*/, selectedMethod(newData)];
            case 1:
                _a = (_b.sent());
                _b.label = 2;
            case 2:
                //useDataURLImage is for crop and eraser tooled images
                _a;
                return [2 /*return*/, createEditsPerformed(newData)];
        }
    });
}); };
export var performImageEditOnly = function (data) { return __awaiter(void 0, void 0, void 0, function () {
    var editType, selectedMethod, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                editType = data.editType;
                selectedMethod = methodConfig[editType];
                _a = selectedMethod;
                if (!_a) return [3 /*break*/, 2];
                return [4 /*yield*/, selectedMethod(data)];
            case 1:
                _a = (_b.sent());
                _b.label = 2;
            case 2:
                _a;
                return [2 /*return*/];
        }
    });
}); };
var performEditGamma = function (_a) {
    var canvas = _a.canvas, img = _a.img, editParams = _a.editParams;
    return __awaiter(void 0, void 0, void 0, function () {
        var params;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    params = editParams;
                    return [4 /*yield*/, applyGamma(canvas, img, params)];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
};
var performEditWhiteBalance = function (_a) {
    var canvas = _a.canvas, img = _a.img, editParams = _a.editParams;
    return __awaiter(void 0, void 0, void 0, function () {
        var params;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    params = editParams;
                    return [4 /*yield*/, applyWhiteBalance(canvas, img, params)];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
};
var performEditCrop = function (_a) {
    var editParams = _a.editParams, canvas = _a.canvas, img = _a.img, imageId = _a.imageId;
    return __awaiter(void 0, void 0, void 0, function () {
        var matchingComp, fullSizeProcessedImage, _b, top, left, width, height, rotate, crop;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, storeModules.stiletto.findMatchingComponent(imageId)];
                case 1:
                    matchingComp = _c.sent();
                    fullSizeProcessedImage = matchingComp === null || matchingComp === void 0 ? void 0 : matchingComp.url.fullSize;
                    _b = editParams, top = _b.top, left = _b.left, width = _b.width, height = _b.height, rotate = _b.rotate;
                    crop = {
                        top: top,
                        left: left,
                        width: width,
                        height: height,
                        rotate: rotate,
                    };
                    return [4 /*yield*/, applyCropImageClipScale(canvas, img, crop, fullSizeProcessedImage)];
                case 2:
                    _c.sent();
                    return [2 /*return*/];
            }
        });
    });
};
var performEditBrightness = function (_a) {
    var canvas = _a.canvas, img = _a.img, editParams = _a.editParams;
    return __awaiter(void 0, void 0, void 0, function () {
        var brightness;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    brightness = editParams;
                    return [4 /*yield*/, applyBrightness(canvas, img, brightness)];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
};
var performEditGradient = function (_a) {
    var canvas = _a.canvas, editParams = _a.editParams;
    return __awaiter(void 0, void 0, void 0, function () {
        var params;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    params = editParams;
                    return [4 /*yield*/, applyLinearVignetteAllEdges(canvas, params, true)];
                case 1:
                    _b.sent();
                    return [2 /*return*/];
            }
        });
    });
};
var preferToHandleInController = function (data) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        //THIS IS HANLDED IN StileetoEditImageController.vue to create a new canvas
        data;
        return [2 /*return*/];
    });
}); };
var preferToHandlInStore = function (data) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        //This is handled in stiletto store.
        data;
        return [2 /*return*/];
    });
}); };
var createEditsPerformed = function (data) {
    var _a;
    var editType = data.editType, editParams = data.editParams, previousEditsPerformed = data.previousEditsPerformed;
    var key = stilettoEditTypeMapToEditHistory[editType];
    var editsPerformed = __assign(__assign({}, previousEditsPerformed), (_a = {}, _a[key] = editParams, _a));
    return __assign(__assign({}, data), { editsPerformed: editsPerformed });
};
export var initializeCanvasEditCreator = function (editType, _a) {
    var crop = _a.crop, gradient = _a.gradient, whiteBalance = _a.whiteBalance;
    if (editType === StilettoEditTypes.editCrop) {
        var params = {
            top: crop.top,
            left: crop.left,
            width: crop.width,
            height: crop.width,
            rotate: crop.rotate || 0,
        };
        return params;
    }
    if (editType === StilettoEditTypes.editWhiteBalance) {
        return whiteBalance;
    }
    if (editType === StilettoEditTypes.editBrightness) {
        return 0;
    }
    if (editType === StilettoEditTypes.editEraser) {
        return null;
    }
    if (editType === StilettoEditTypes.editGradient) {
        var params = Object.keys(VignettePosition).map(function (position) { return ({
            position: position,
            percent: gradient[position],
            featherPercent: 50,
            featherOpacity: 50,
        }); });
        return params;
    }
    return null;
};

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { timeout } from 'vue2/helpers';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import omit from 'lodash/omit';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
export var uploadManagerModuleName = 'uploadManager';
var TIME_UNTIL_FILE_REMOVED = 1000;
var UploadManager = /** @class */ (function (_super) {
    __extends(UploadManager, _super);
    function UploadManager() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.files = {};
        _this.eventListenerAdded = false;
        return _this;
    }
    Object.defineProperty(UploadManager.prototype, "uploadingIds", {
        get: function () {
            return Object.keys(this.files) || [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UploadManager.prototype, "allTotals", {
        get: function () {
            return Object.values(this.files)
                .map(function (_a) {
                var total = _a.total;
                return total;
            })
                .reduce(function (acc, curr) { return acc + curr; }, 0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UploadManager.prototype, "allLoaded", {
        get: function () {
            return Object.values(this.files)
                .map(function (_a) {
                var loaded = _a.loaded;
                return loaded;
            })
                .reduce(function (acc, curr) { return acc + curr; }, 0);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UploadManager.prototype, "totalProgress", {
        get: function () {
            return Math.floor((this.allLoaded * 100) / this.allTotals);
        },
        enumerable: false,
        configurable: true
    });
    UploadManager.prototype.UPDATE_FILE_PROGESS = function (data) {
        var _a;
        this.files = __assign(__assign({}, this.files), (_a = {}, _a[data.id] = data, _a));
    };
    UploadManager.prototype.REMOVE_ID_FROM_FILES = function (id) {
        var newFiles = omit(this.files, id);
        this.files = __assign({}, newFiles);
    };
    UploadManager.prototype.changeIsEventListenerAdded = function (value) {
        this.eventListenerAdded = value;
    };
    UploadManager.prototype.uploadFile = function (_a) {
        var _this = this;
        var id = _a.id, file = _a.file, params = _a.params, axiosInstance = _a.axiosInstance, routeName = _a.routeName, onUploadProgress = _a.onUploadProgress;
        var formData = new FormData();
        if (isArray(file)) {
            file.forEach(function (f) { return formData.append(f.key, f.value); });
        }
        if (params) {
            Object.entries(params).forEach(function (_a) {
                var key = _a[0], value = _a[1];
                formData.append(key, String(value));
            });
        }
        var unloadHandlerMessage = function (event) {
            event.preventDefault();
            return (event.returnValue = 'Are you sure you want to exit?');
        };
        window.addEventListener('beforeunload', unloadHandlerMessage, { capture: true });
        this.changeIsEventListenerAdded(true);
        return axiosInstance
            .post(routeName, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: function (_a) {
                var loaded = _a.loaded, total = _a.total;
                var details = {
                    progress: Math.floor((loaded * 100) / total),
                    total: Math.floor(total / 1024),
                    loaded: Math.floor(loaded / 1024),
                    completed: false,
                    id: id,
                };
                _this.UPDATE_FILE_PROGESS(details);
                onUploadProgress === null || onUploadProgress === void 0 ? void 0 : onUploadProgress(details);
            },
        })
            .then(function (_a) {
            var data = _a.data;
            return __awaiter(_this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_b) {
                    this.UPDATE_FILE_PROGESS(__assign(__assign({}, this.files[id]), { completed: true }));
                    timeout(TIME_UNTIL_FILE_REMOVED).then(function () {
                        _this.REMOVE_ID_FROM_FILES(id);
                        if (isEmpty(_this.uploadingIds)) {
                            _this.changeIsEventListenerAdded(false);
                            window.removeEventListener('beforeunload', unloadHandlerMessage, { capture: true });
                        }
                    });
                    return [2 /*return*/, data];
                });
            });
        })
            .catch(function (data) {
            _this.REMOVE_ID_FROM_FILES(id);
            throw data;
        });
    };
    __decorate([
        Mutation
    ], UploadManager.prototype, "UPDATE_FILE_PROGESS", null);
    __decorate([
        Mutation
    ], UploadManager.prototype, "REMOVE_ID_FROM_FILES", null);
    __decorate([
        Mutation
    ], UploadManager.prototype, "changeIsEventListenerAdded", null);
    __decorate([
        Action
    ], UploadManager.prototype, "uploadFile", null);
    UploadManager = __decorate([
        Module({ namespaced: true, name: uploadManagerModuleName })
    ], UploadManager);
    return UploadManager;
}(VuexModule));
export default UploadManager;

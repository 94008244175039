var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Watch, Emit, Mixins } from 'vue-property-decorator';
import AppImage from '../AppImage.vue';
import { AppIcon } from '../../Icon';
import first from 'lodash/first';
import last from 'lodash/last';
import isEqual from 'lodash/isEqual';
import { extractLightboxImage } from '../helpers';
import { AppButton } from '../../Button';
import isEmpty from 'lodash/isEmpty';
import { getElementPosition, nextInArray, previousInArray, timeout } from '../../../helpers';
import { ToggleIcon } from '../../Slider';
import { KeyboardKeys } from '../../../config';
import Draggable from '../../../mixins/draggable';
var IMAGE_SCALE_STEPS = [1, 1.5, 2, 3, 4];
var ImageToggleComponent = /** @class */ (function (_super) {
    __extends(ImageToggleComponent, _super);
    function ImageToggleComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.internalCurrentImage = null;
        _this.imageScale = 1;
        _this.imageRefName = 'imageRefName';
        _this.rotationDegree = 0;
        return _this;
    }
    Object.defineProperty(ImageToggleComponent.prototype, "hasMultipleImages", {
        get: function () {
            return this.images.length > 1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ImageToggleComponent.prototype, "showRightToggle", {
        get: function () {
            //@ts-ignore
            return !isEqual(this.internalCurrentImage, last(this.images)) || this.loop;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ImageToggleComponent.prototype, "showLeftToggle", {
        get: function () {
            //@ts-ignore
            return !isEqual(this.internalCurrentImage, first(this.images)) || this.loop;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ImageToggleComponent.prototype, "currentImageObject", {
        get: function () {
            var _this = this;
            return (this.images &&
                //@ts-ignore
                this.images.find(function (image) { return isEqual(extractLightboxImage(image), _this.internalCurrentImage); }));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ImageToggleComponent.prototype, "scaleButtons", {
        get: function () {
            return this.useZoom ? [] : [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ImageToggleComponent.prototype, "buttons", {
        get: function () {
            var _a;
            return !isEmpty((_a = this.currentImageObject) === null || _a === void 0 ? void 0 : _a.buttons) ? this.currentImageObject.buttons : [];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ImageToggleComponent.prototype, "title", {
        get: function () {
            var _a;
            return (_a = this.currentImageObject) === null || _a === void 0 ? void 0 : _a.title;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ImageToggleComponent.prototype, "imageStyle", {
        get: function () {
            return {
                transform: "scale(".concat(this.imageScale, ") rotate(").concat(this.rotationDegree, "deg)"),
                cursor: this.useZoom && 'zoom-in',
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ImageToggleComponent.prototype, "preloadedArray", {
        get: function () {
            var prevImage = this.getNextImage(false);
            var nextImage = this.getNextImage(true);
            return [prevImage, nextImage];
        },
        enumerable: false,
        configurable: true
    });
    ImageToggleComponent.prototype.zoomImage = function (_a) {
        var _b = _a.reset, reset = _b === void 0 ? false : _b, _c = _a.zoom, zoom = _c === void 0 ? true : _c;
        if (reset || !this.useZoom) {
            this.imageScale = IMAGE_SCALE_STEPS[0];
            this.$_resetDrag();
            return;
        }
        if (!this.isInitialized) {
            //@ts-ignore
            this.$_dragInit(this.$refs[this.imageRefName].$el);
        }
        var hasDragged = !isEqual(this.originalPosition, getElementPosition(this.dragElement));
        if (this.useZoom && !hasDragged) {
            this.imageScale = zoom
                ? nextInArray(IMAGE_SCALE_STEPS, this.imageScale)
                : previousInArray(IMAGE_SCALE_STEPS, this.imageScale, false);
            if (isEqual(this.imageScale, first(IMAGE_SCALE_STEPS))) {
                this.$_resetDrag();
            }
        }
    };
    ImageToggleComponent.prototype.onRotate = function (src) {
        this.rotationDegree = this.rotationDegree + 90; //TODO think about how we will integrate with API
        return src;
    };
    ImageToggleComponent.prototype.listenForKeyboardEvents = function (event) {
        var _a;
        var _this = this;
        if (this.useArrowKeys) {
            var eventMap = (_a = {},
                _a[KeyboardKeys.left] = function () { return _this.changeImage(false); },
                _a[KeyboardKeys.right] = function () { return _this.changeImage(true); },
                _a[KeyboardKeys.escape] = function () { return _this.onExitClick(); },
                _a);
            var selectedFunction = eventMap[event.key];
            selectedFunction && selectedFunction();
        }
    };
    ImageToggleComponent.prototype.getNextImage = function (next) {
        if (next === void 0) { next = true; }
        var imageStrings = this.images.map(function (img) { return extractLightboxImage(img); });
        var nextImage = next
            ? nextInArray(imageStrings, this.internalCurrentImage)
            : previousInArray(imageStrings, this.internalCurrentImage);
        return extractLightboxImage(nextImage);
    };
    ImageToggleComponent.prototype.changeImage = function (next) {
        if (next === void 0) { next = true; }
        this.zoomImage({ reset: true });
        this.rotationDegree = 0;
        this.internalCurrentImage = this.getNextImage(next);
    };
    ImageToggleComponent.prototype.onExitClick = function () {
        var _this = this;
        timeout(450).then(function () {
            _this.zoomImage({ reset: true });
        });
        return;
    };
    ImageToggleComponent.prototype.created = function () {
        //@ts-ignore
        this.internalCurrentImage = extractLightboxImage(this.currentImage || first(this.images));
    };
    ImageToggleComponent.prototype.mounted = function () {
        document.addEventListener('keyup', this.listenForKeyboardEvents);
    };
    ImageToggleComponent.prototype.beforedDestory = function () {
        document.removeEventListener('keyup', this.listenForKeyboardEvents);
    };
    ImageToggleComponent.prototype.currentImageChange = function () {
        this.internalCurrentImage = extractLightboxImage(this.currentImage);
    };
    __decorate([
        Prop({ default: function () { return []; } })
    ], ImageToggleComponent.prototype, "images", void 0);
    __decorate([
        Prop({ default: true })
    ], ImageToggleComponent.prototype, "loop", void 0);
    __decorate([
        Prop()
    ], ImageToggleComponent.prototype, "currentImage", void 0);
    __decorate([
        Prop()
    ], ImageToggleComponent.prototype, "useZoom", void 0);
    __decorate([
        Prop({ default: true })
    ], ImageToggleComponent.prototype, "useArrowKeys", void 0);
    __decorate([
        Prop()
    ], ImageToggleComponent.prototype, "useRotate", void 0);
    __decorate([
        Emit('onRotate')
    ], ImageToggleComponent.prototype, "onRotate", null);
    __decorate([
        Emit('onExitClick')
    ], ImageToggleComponent.prototype, "onExitClick", null);
    __decorate([
        Watch('currentImage')
    ], ImageToggleComponent.prototype, "currentImageChange", null);
    ImageToggleComponent = __decorate([
        Component({ components: { AppImage: AppImage, AppIcon: AppIcon, AppButton: AppButton, ToggleIcon: ToggleIcon } })
    ], ImageToggleComponent);
    return ImageToggleComponent;
}(Mixins(Draggable)));
export default ImageToggleComponent;

import { RouteNames } from './routeNames';
export var routes = [
    {
        path: "/".concat(RouteNames.ShowroomAssistant),
        name: RouteNames.ShowroomAssistant,
        component: function () { return import('../views/ShowroomAssistant.vue'); },
        meta: {
            title: 'Showroom Assistant',
        },
        children: [
            {
                path: RouteNames.ShowroomAssistantTour,
                name: RouteNames.ShowroomAssistantTour,
                meta: {
                    title: 'Showroom Assistant - Tour',
                },
            },
            {
                path: RouteNames.ShowroomAssistantDropoff,
                name: RouteNames.ShowroomAssistantDropoff,
                meta: {
                    title: 'Showroom Assistant - Dropoff',
                },
            },
            {
                path: RouteNames.ShowroomAssistantPickup,
                name: RouteNames.ShowroomAssistantPickup,
                meta: {
                    title: 'Showroom Assistant - Pickup',
                },
            },
            {
                path: RouteNames.ShowroomAssistantNewQuoteWeb,
                name: RouteNames.ShowroomAssistantNewQuoteWeb,
                meta: {
                    title: 'Showroom Assistant - New Quote',
                },
            },
            {
                path: RouteNames.ShowroomAssistantNewQuoteAssistant,
                name: RouteNames.ShowroomAssistantNewQuoteAssistant,
                meta: {
                    title: 'Showroom Assistant - New Quote',
                },
            },
        ],
    },
    {
        path: "/".concat(RouteNames.ShowroomAssistantComplete),
        name: RouteNames.ShowroomAssistantComplete,
        component: function () { return import('../views/Receive.vue'); },
        meta: {
            title: 'Showroom Assistant - Complete',
        },
    },
    {
        path: "/".concat(RouteNames.ShowroomAssistantDialog),
        name: RouteNames.ShowroomAssistantDialog,
        component: function () { return import('../views/Dialog.vue'); },
        meta: {
            requiresAuth: true,
            title: 'Showroom Assistant - Dialog',
        },
    },
    {
        path: "/".concat(RouteNames.ShowroomAssistantReceive),
        name: RouteNames.ShowroomAssistantReceive,
        component: function () { return import('../views/Receive.vue'); },
        meta: {
            title: 'Showroom Assistant - Receive',
        },
    },
];
export default routes;

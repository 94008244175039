var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Vue from 'vue';
import Router from 'vue-router';
import showroomAssisantRoutes from './showroomAssistantRoutes';
import errorRoutes from './errors';
import mechanicalMatcherRoutes from './mechanicalMatcherRoutes';
import stilettoRoutes from './stilettoRoutes';
import indexRoutes from './indexRoutes';
import { storeModules } from '../store';
import { RouteNames } from './routeNames';
import { PORTAL_REFRESH_TOKEN } from 'main/api';
import { redirectToUrl } from 'vue2/helpers';
import { setHeaderData } from './routerHelpers';
Vue.use(Router);
var routes = __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], indexRoutes, true), showroomAssisantRoutes, true), mechanicalMatcherRoutes, true), stilettoRoutes, true), errorRoutes, true);
var router = new Router({
    mode: 'history',
    routes: routes,
});
router.beforeEach(function (to, from, next) {
    if (to.matched.some(function (route) { return route.meta.requiresAuth; }) && !storeModules.user.isLoggedIn) {
        next({ name: RouteNames.Login, query: { redirect: to.fullPath } });
        return;
    }
    if (to.matched.some(function (route) { return route.meta.requiresAdminAuth; }) && !Vue.$cookies.get(PORTAL_REFRESH_TOKEN)) {
        redirectToUrl("/".concat(RouteNames.Portal));
        return;
    }
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(function () {
        setHeaderData(to, from);
        next();
    });
});
export default router;
export * from './externalRoutes';

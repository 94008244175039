var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { getMessageById, getStartingMessage, createMessage, getAllBuiltMessageBeforeId } from 'main/helpers';
import { initialMessagesRecord } from 'main/helpers/createMessages';
import { defaultUserData, defaultUserNewQuoteData } from './config';
import last from 'lodash/last';
export var showroomAssistantModuleName = 'showroomAssistant';
var ShowroomAssistantStore = /** @class */ (function (_super) {
    __extends(ShowroomAssistantStore, _super);
    function ShowroomAssistantStore() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.messages = [];
        _this.displayedMessages = [];
        _this.userDropoffData = defaultUserData;
        _this.userNewQuoteData = defaultUserNewQuoteData;
        _this.dropoffCode = null;
        _this.pickupCode = null;
        _this.tourVariableData = null;
        _this.selectedStyle = null;
        return _this;
    }
    ShowroomAssistantStore.prototype.ADD_MESSAGES = function (newMessages) {
        this.messages = __spreadArray(__spreadArray([], this.messages, true), newMessages, true);
    };
    ShowroomAssistantStore.prototype.REPLACE_MESSAGES = function (newMessages) {
        this.messages = __spreadArray([], newMessages, true);
    };
    ShowroomAssistantStore.prototype.ADD_DISPLAYED_MESSAGES = function (newMessages) {
        this.displayedMessages = __spreadArray(__spreadArray([], this.displayedMessages, true), [newMessages], false);
    };
    ShowroomAssistantStore.prototype.REPLACE_DISPLAYED_MESSAGES = function (newMessages) {
        this.displayedMessages = __spreadArray([], newMessages, true);
    };
    ShowroomAssistantStore.prototype.UPDATE_USER_DROPOFF_DATA = function (newUserData) {
        var newObject = __assign(__assign({}, this.userDropoffData), newUserData);
        this.userDropoffData = newObject;
    };
    ShowroomAssistantStore.prototype.UPDATE_USER_NEW_QUOTE_DATA = function (newNewQuoteData) {
        var newObject = __assign(__assign({}, this.userNewQuoteData), newNewQuoteData);
        this.userNewQuoteData = newObject;
    };
    ShowroomAssistantStore.prototype.REPLACE_USER_DROPOFF_DATA = function (newUserData) {
        this.userDropoffData = __assign({}, newUserData);
    };
    ShowroomAssistantStore.prototype.REPLACE_USER_NEW_QUOTE_DATA = function (newUserData) {
        this.userNewQuoteData = __assign({}, newUserData);
    };
    ShowroomAssistantStore.prototype.SET_SELECTED_STYLE = function (newStyle) {
        this.selectedStyle = newStyle;
    };
    ShowroomAssistantStore.prototype.SET_DROPOFF_CODE = function (newDropoffCode) {
        this.dropoffCode = newDropoffCode;
    };
    ShowroomAssistantStore.prototype.SET_PICKUP_CODE = function (newPickupCode) {
        this.pickupCode = newPickupCode;
    };
    ShowroomAssistantStore.prototype.SET_TOUR_DATA = function (newTourData) {
        this.tourVariableData = newTourData;
    };
    ShowroomAssistantStore.prototype.resetAndStartMessage = function (messageRender, resetUserDropoff) {
        this.resetMessages();
        resetUserDropoff && this.REPLACE_USER_DROPOFF_DATA(defaultUserData);
        var messages = messageRender();
        this.ADD_MESSAGES(messages);
        return getStartingMessage(messages);
    };
    ShowroomAssistantStore.prototype.currentMessageToDisplay = function (route) {
        return __awaiter(this, void 0, void 0, function () {
            var message, chosenRecord, messageId, displayMessages, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        message = last(this.displayedMessages) || null;
                        if (!!message) return [3 /*break*/, 2];
                        chosenRecord = initialMessagesRecord[route.name];
                        return [4 /*yield*/, this.resetAndStartMessage(chosenRecord === null || chosenRecord === void 0 ? void 0 : chosenRecord())];
                    case 1:
                        message = _b.sent();
                        _b.label = 2;
                    case 2:
                        if (!(route.query['messageId'] && getMessageById(route.query['messageId']))) return [3 /*break*/, 6];
                        messageId = route.query['messageId'];
                        return [4 /*yield*/, getAllBuiltMessageBeforeId(messageId)];
                    case 3:
                        displayMessages = _b.sent();
                        this.REPLACE_DISPLAYED_MESSAGES(displayMessages);
                        _a = last(displayMessages);
                        if (_a) return [3 /*break*/, 5];
                        return [4 /*yield*/, createMessage(getMessageById(messageId))];
                    case 4:
                        _a = (_b.sent());
                        _b.label = 5;
                    case 5:
                        message = _a;
                        _b.label = 6;
                    case 6: return [2 /*return*/, message];
                }
            });
        });
    };
    ShowroomAssistantStore.prototype.reset = function () {
        this.resetMessages();
        this.REPLACE_USER_DROPOFF_DATA(defaultUserData);
        this.REPLACE_USER_NEW_QUOTE_DATA(defaultUserNewQuoteData);
        this.SET_DROPOFF_CODE(null);
        this.SET_PICKUP_CODE(null);
        this.SET_SELECTED_STYLE(null);
    };
    ShowroomAssistantStore.prototype.resetMessages = function () {
        this.REPLACE_MESSAGES([]);
        this.REPLACE_DISPLAYED_MESSAGES([]);
    };
    __decorate([
        Mutation
    ], ShowroomAssistantStore.prototype, "ADD_MESSAGES", null);
    __decorate([
        Mutation
    ], ShowroomAssistantStore.prototype, "REPLACE_MESSAGES", null);
    __decorate([
        Mutation
    ], ShowroomAssistantStore.prototype, "ADD_DISPLAYED_MESSAGES", null);
    __decorate([
        Mutation
    ], ShowroomAssistantStore.prototype, "REPLACE_DISPLAYED_MESSAGES", null);
    __decorate([
        Mutation
    ], ShowroomAssistantStore.prototype, "UPDATE_USER_DROPOFF_DATA", null);
    __decorate([
        Mutation
    ], ShowroomAssistantStore.prototype, "UPDATE_USER_NEW_QUOTE_DATA", null);
    __decorate([
        Mutation
    ], ShowroomAssistantStore.prototype, "REPLACE_USER_DROPOFF_DATA", null);
    __decorate([
        Mutation
    ], ShowroomAssistantStore.prototype, "REPLACE_USER_NEW_QUOTE_DATA", null);
    __decorate([
        Mutation
    ], ShowroomAssistantStore.prototype, "SET_SELECTED_STYLE", null);
    __decorate([
        Mutation
    ], ShowroomAssistantStore.prototype, "SET_DROPOFF_CODE", null);
    __decorate([
        Mutation
    ], ShowroomAssistantStore.prototype, "SET_PICKUP_CODE", null);
    __decorate([
        Mutation
    ], ShowroomAssistantStore.prototype, "SET_TOUR_DATA", null);
    __decorate([
        Action
    ], ShowroomAssistantStore.prototype, "resetAndStartMessage", null);
    __decorate([
        Action
    ], ShowroomAssistantStore.prototype, "currentMessageToDisplay", null);
    __decorate([
        Action
    ], ShowroomAssistantStore.prototype, "reset", null);
    __decorate([
        Action
    ], ShowroomAssistantStore.prototype, "resetMessages", null);
    ShowroomAssistantStore = __decorate([
        Module({ namespaced: true, name: showroomAssistantModuleName })
    ], ShowroomAssistantStore);
    return ShowroomAssistantStore;
}(VuexModule));
export default ShowroomAssistantStore;

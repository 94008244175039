var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { AppImage } from '../../Image';
import isEmpty from 'lodash/isEmpty';
import { timeout } from '../../../helpers';
import { AppIcon } from '../../Icon';
import AppSelectShelfOverlay from './AppSelectShelfOverlay.vue';
var AppSelectShelfSingle = /** @class */ (function (_super) {
    __extends(AppSelectShelfSingle, _super);
    function AppSelectShelfSingle() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isOpen = false;
        return _this;
    }
    Object.defineProperty(AppSelectShelfSingle.prototype, "hasSelectedValue", {
        get: function () {
            return !isEmpty(this.value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppSelectShelfSingle.prototype, "hasValueOptions", {
        get: function () {
            return !isEmpty(this.value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppSelectShelfSingle.prototype, "hasOptions", {
        get: function () {
            return !isEmpty(this.options);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppSelectShelfSingle.prototype, "selectedText", {
        get: function () {
            var _a;
            if (!this.value) {
                return 'No option selected';
            }
            if (!this.hasOptions) {
                return this.noOptionsText;
            }
            return (_a = this.value) === null || _a === void 0 ? void 0 : _a.label;
        },
        enumerable: false,
        configurable: true
    });
    AppSelectShelfSingle.prototype.isSelected = function (option) {
        var _a;
        if (this.removeable)
            return;
        return ((_a = this.value) === null || _a === void 0 ? void 0 : _a.code) === option.code;
    };
    AppSelectShelfSingle.prototype.open = function () {
        this.isOpen = true && !this.disabled;
    };
    AppSelectShelfSingle.prototype.close = function () {
        this.isOpen = false;
    };
    AppSelectShelfSingle.prototype.onClickFinish = function () {
        this.close();
        return;
    };
    AppSelectShelfSingle.prototype.onClick = function (data) {
        var _this = this;
        timeout(50).then(function () {
            _this.close();
        });
        this.input(data);
    };
    AppSelectShelfSingle.prototype.input = function (data) {
        return data;
    };
    AppSelectShelfSingle.prototype.remove = function (data) {
        return data;
    };
    AppSelectShelfSingle.prototype.reset = function () {
        this.close();
        return;
    };
    AppSelectShelfSingle.prototype.created = function () {
        if (this.value)
            return this.input(this.value);
        if (this.removeable)
            return this.input(this.options);
        if (this.preselectFirst)
            return this.input(this.options[0]);
    };
    AppSelectShelfSingle.prototype.mounted = function () {
        if (this.autoOpen)
            this.open();
    };
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], AppSelectShelfSingle.prototype, "value", void 0);
    __decorate([
        Prop({ default: function () { return []; } })
    ], AppSelectShelfSingle.prototype, "options", void 0);
    __decorate([
        Prop({ default: 'Sorry, no matching options.' })
    ], AppSelectShelfSingle.prototype, "noOptionsText", void 0);
    __decorate([
        Prop({ default: false })
    ], AppSelectShelfSingle.prototype, "preselectFirst", void 0);
    __decorate([
        Prop()
    ], AppSelectShelfSingle.prototype, "multiple", void 0);
    __decorate([
        Prop()
    ], AppSelectShelfSingle.prototype, "disabled", void 0);
    __decorate([
        Prop()
    ], AppSelectShelfSingle.prototype, "removeable", void 0);
    __decorate([
        Prop()
    ], AppSelectShelfSingle.prototype, "autoOpen", void 0);
    __decorate([
        Prop({ default: 'No items selected' })
    ], AppSelectShelfSingle.prototype, "noSelectText", void 0);
    __decorate([
        Prop()
    ], AppSelectShelfSingle.prototype, "minWidthImage", void 0);
    __decorate([
        Emit('close')
    ], AppSelectShelfSingle.prototype, "close", null);
    __decorate([
        Emit('onClickFinish')
    ], AppSelectShelfSingle.prototype, "onClickFinish", null);
    __decorate([
        Emit('input')
    ], AppSelectShelfSingle.prototype, "input", null);
    __decorate([
        Emit('remove')
    ], AppSelectShelfSingle.prototype, "remove", null);
    __decorate([
        Emit('reset')
    ], AppSelectShelfSingle.prototype, "reset", null);
    AppSelectShelfSingle = __decorate([
        Component({ components: { AppImage: AppImage, AppIcon: AppIcon, AppSelectShelfOverlay: AppSelectShelfOverlay } })
    ], AppSelectShelfSingle);
    return AppSelectShelfSingle;
}(Vue));
export default AppSelectShelfSingle;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import { BasicOverlay } from '../../Overlays';
import ImageToggleComponent from './ImageToggleComponent.vue';
import isEmpty from 'lodash/isEmpty';
var AppImageLightboxDouble = /** @class */ (function (_super) {
    __extends(AppImageLightboxDouble, _super);
    function AppImageLightboxDouble() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(AppImageLightboxDouble.prototype, "hasImageLeft", {
        get: function () {
            return !isEmpty(this.imagesLeft);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppImageLightboxDouble.prototype, "hasImageRight", {
        get: function () {
            return !isEmpty(this.imagesRight);
        },
        enumerable: false,
        configurable: true
    });
    AppImageLightboxDouble.prototype.onExitClick = function () {
        return;
    };
    __decorate([
        Prop()
    ], AppImageLightboxDouble.prototype, "isOpen", void 0);
    __decorate([
        Prop()
    ], AppImageLightboxDouble.prototype, "currentLeftImage", void 0);
    __decorate([
        Prop()
    ], AppImageLightboxDouble.prototype, "currentRightImage", void 0);
    __decorate([
        Prop({ default: function () { return []; } })
    ], AppImageLightboxDouble.prototype, "imagesLeft", void 0);
    __decorate([
        Prop({ default: function () { return []; } })
    ], AppImageLightboxDouble.prototype, "imagesRight", void 0);
    __decorate([
        Prop({ default: true })
    ], AppImageLightboxDouble.prototype, "loop", void 0);
    __decorate([
        Prop()
    ], AppImageLightboxDouble.prototype, "imageRightTitle", void 0);
    __decorate([
        Prop()
    ], AppImageLightboxDouble.prototype, "imageLeftTitle", void 0);
    __decorate([
        Prop()
    ], AppImageLightboxDouble.prototype, "useRotate", void 0);
    __decorate([
        Emit('onExitClick')
    ], AppImageLightboxDouble.prototype, "onExitClick", null);
    AppImageLightboxDouble = __decorate([
        Component({ components: { BasicOverlay: BasicOverlay, ImageToggleComponent: ImageToggleComponent } })
    ], AppImageLightboxDouble);
    return AppImageLightboxDouble;
}(Vue));
export default AppImageLightboxDouble;

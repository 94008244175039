var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { fabric } from "fabric";
import { getCanvasDimensions, setImageToCanvasSize } from "./canvas";
import min from "lodash/min";
import { timeout } from "vue2/helpers";
export var applyCropImageClipScale = function (canvas, img, options, fallbackImage) { return __awaiter(void 0, void 0, void 0, function () {
    var left, top, rotate, width, height, ogImageWidth, ogImageHeight, _a, canvasHeight, canvasWidth, scaleX, scaleY, clipPath;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                left = options.left, top = options.top, rotate = options.rotate;
                width = options.width, height = options.height;
                ogImageWidth = img.width;
                ogImageHeight = img.height;
                _a = getCanvasDimensions(canvas), canvasHeight = _a.canvasHeight, canvasWidth = _a.canvasWidth;
                scaleX = canvasWidth / width;
                scaleY = canvasHeight / height;
                width = min([ogImageWidth, width]);
                height = min([ogImageHeight, height]);
                img.set({ centeredScaling: true, centeredRotation: true });
                clipPath = new fabric.Rect({
                    width: width,
                    height: height,
                    absolutePositioned: false,
                });
                if (!rotate) return [3 /*break*/, 8];
                if (!(rotate === 90)) return [3 /*break*/, 1];
                img.rotate(rotate);
                clipPath.set({
                    top: Number(img.height) / 2 - left,
                    left: -Number(img.width) / 2 + top,
                    originX: "left",
                    originY: "bottom",
                });
                img.set({ clipPath: clipPath });
                img.scaleX = scaleX;
                img.top = -top * scaleX;
                img.scaleY = scaleY;
                img.left = -left * scaleY + Number(img.height) * scaleY;
                return [3 /*break*/, 7];
            case 1:
                if (!(rotate === -90)) return [3 /*break*/, 2];
                img.rotate(rotate);
                clipPath.set({
                    top: -Number(img.height) / 2 + left,
                    left: Number(img.width) / 2 - top,
                    originX: "right",
                    originY: "top",
                });
                img.set({ clipPath: clipPath });
                img.scaleX = scaleX;
                img.top = Number(img.width) * scaleX - top * scaleX;
                img.scaleY = scaleY;
                img.left = -left * scaleY;
                return [3 /*break*/, 7];
            case 2:
                if (!(rotate === 180 || rotate === -180)) return [3 /*break*/, 3];
                img.rotate(rotate);
                clipPath.set({
                    top: Number(img.height) / 2 - top,
                    left: Number(img.width) / 2 - left,
                    originX: "left",
                    originY: "top",
                    angle: rotate,
                });
                img.set({ clipPath: clipPath });
                img.scaleX = scaleX;
                img.scaleY = scaleY;
                img.top = -top * img.scaleY + Number(img.height) * img.scaleY;
                img.left = -left * img.scaleX + Number(img.width) * img.scaleX;
                return [3 /*break*/, 7];
            case 3: 
            //TODO this is a hack... figure out how to do it for reals and apply a clip crop
            return [4 /*yield*/, canvas.backgroundImage.setSrc(fallbackImage)];
            case 4:
                //TODO this is a hack... figure out how to do it for reals and apply a clip crop
                _b.sent();
                return [4 /*yield*/, timeout(0)];
            case 5:
                _b.sent();
                return [4 /*yield*/, setImageToCanvasSize(canvas, canvas.backgroundImage)];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7:
                canvas.renderAll();
                return [2 /*return*/];
            case 8:
                //CREATE CLIP
                clipPath.set({
                    top: -ogImageHeight / 2 + top,
                    left: -ogImageWidth / 2 + left,
                    originX: "left",
                    originY: "top",
                });
                img.set({ clipPath: clipPath });
                //APPLY IMAGE CROP
                img.scaleX = scaleX;
                img.scaleY = scaleY;
                img.top = -top * img.scaleY;
                img.left = -left * img.scaleX;
                canvas.renderAll();
                return [2 /*return*/];
        }
    });
}); };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { getCoordinates } from '../../helpers';
import round from 'lodash/round';
import { AppButton } from '../Button';
import { CoordinateIds, handleXDrag, handleYDrag } from './helpers';
var AppAdjustLineImage = /** @class */ (function (_super) {
    __extends(AppAdjustLineImage, _super);
    function AppAdjustLineImage() {
        var _a;
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dragging = (_a = {},
            _a[CoordinateIds.top] = false,
            _a[CoordinateIds.left] = false,
            _a[CoordinateIds.right] = false,
            _a[CoordinateIds.bottom] = false,
            _a);
        _this.parentElementCoordinates = { top: 0, left: 0, width: 0, height: 0 };
        _this.dragElementPercentBottom = 8;
        _this.dragElementPercentTop = 8;
        _this.dragElementPercentLeft = 8;
        _this.dragElementPercentRight = 8;
        return _this;
    }
    Object.defineProperty(AppAdjustLineImage.prototype, "dragElement", {
        get: function () {
            return [
                {
                    id: CoordinateIds.left,
                    value: this.dragElementPercentLeft,
                    style: { width: "".concat(this.dragElementPercentLeft, "%") },
                    rounded: this.createRoundedText(this.dragElementPercentLeft),
                },
                {
                    id: CoordinateIds.right,
                    value: this.dragElementPercentRight,
                    style: { width: "".concat(this.dragElementPercentRight, "%") },
                    rounded: this.createRoundedText(this.dragElementPercentRight),
                },
                {
                    id: CoordinateIds.top,
                    value: this.dragElementPercentTop,
                    style: { height: "".concat(this.dragElementPercentTop, "%") },
                    rounded: this.createRoundedText(this.dragElementPercentTop),
                },
                {
                    id: CoordinateIds.bottom,
                    value: this.dragElementPercentBottom,
                    style: { height: "".concat(this.dragElementPercentBottom, "%") },
                    rounded: this.createRoundedText(this.dragElementPercentBottom),
                },
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppAdjustLineImage.prototype, "dynamicStyleParent", {
        get: function () {
            return {
                height: "".concat(this.height, "px"),
                width: "".concat(this.width, "px"),
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppAdjustLineImage.prototype, "dragValues", {
        get: function () {
            return {
                top: this.dragElementPercentTop,
                bottom: this.dragElementPercentBottom,
                left: this.dragElementPercentLeft,
                right: this.dragElementPercentRight,
            };
        },
        enumerable: false,
        configurable: true
    });
    AppAdjustLineImage.prototype.createRoundedText = function (amount) {
        return "".concat(round(amount), "%");
    };
    AppAdjustLineImage.prototype.onDragStart = function (e, id) {
        var _this = this;
        e.preventDefault();
        this.dragging[id] = true;
        window.onmouseup = this.onDragEnd;
        //@ts-ignore
        window.onmousemove = function (e) { return _this.onDragMoving(e, id); };
        this.parentElementCoordinates = getCoordinates(this.$refs.container);
    };
    AppAdjustLineImage.prototype.onDragMoving = function (e, id) {
        var isYAxis = [CoordinateIds.top, CoordinateIds.bottom].includes(id);
        if (isYAxis) {
            var hoverPercent = handleYDrag({
                top: this.parentElementCoordinates.top,
                id: id,
                parentHeight: this.parentElementCoordinates.height,
                e: e,
                dragElementPercentTop: this.dragElementPercentTop,
                dragElementPercentBottom: this.dragElementPercentBottom,
                preventOverflow: this.preventOverflow,
            });
            if (id === CoordinateIds.bottom) {
                this.dragElementPercentBottom = hoverPercent;
            }
            else {
                this.dragElementPercentTop = hoverPercent;
            }
        }
        else {
            var hoverPercent = handleXDrag({
                left: this.parentElementCoordinates.left,
                id: id,
                parentWidth: this.parentElementCoordinates.width,
                e: e,
                dragElementPercentLeft: this.dragElementPercentLeft,
                dragElementPercentRight: this.dragElementPercentRight,
                preventOverflow: this.preventOverflow,
            });
            if (id === CoordinateIds.left) {
                this.dragElementPercentLeft = hoverPercent;
            }
            else {
                this.dragElementPercentRight = hoverPercent;
            }
        }
        return this.dragValues;
    };
    AppAdjustLineImage.prototype.onDragEnd = function () {
        var _this = this;
        Object.values(CoordinateIds).forEach(function (id) { return (_this.dragging[id] = false); });
        window.onmouseup = null;
        window.onmousemove = null;
        return this.dragValues;
    };
    AppAdjustLineImage.prototype.onReady = function () {
        return;
    };
    AppAdjustLineImage.prototype.created = function () {
        if (this.percents) {
            this.dragElementPercentBottom = this.percents.bottom;
            this.dragElementPercentLeft = this.percents.left;
            this.dragElementPercentRight = this.percents.right;
            this.dragElementPercentTop = this.percents.top;
        }
    };
    __decorate([
        Prop()
    ], AppAdjustLineImage.prototype, "percents", void 0);
    __decorate([
        Prop()
    ], AppAdjustLineImage.prototype, "saving", void 0);
    __decorate([
        Prop({ default: true })
    ], AppAdjustLineImage.prototype, "preventOverflow", void 0);
    __decorate([
        Prop({ default: true })
    ], AppAdjustLineImage.prototype, "showSaveButtons", void 0);
    __decorate([
        Prop()
    ], AppAdjustLineImage.prototype, "loadingImage", void 0);
    __decorate([
        Prop()
    ], AppAdjustLineImage.prototype, "height", void 0);
    __decorate([
        Prop()
    ], AppAdjustLineImage.prototype, "width", void 0);
    __decorate([
        Prop()
    ], AppAdjustLineImage.prototype, "active", void 0);
    __decorate([
        Emit('onDrag')
    ], AppAdjustLineImage.prototype, "onDragMoving", null);
    __decorate([
        Emit('onDragEnd')
    ], AppAdjustLineImage.prototype, "onDragEnd", null);
    __decorate([
        Emit('onReady')
    ], AppAdjustLineImage.prototype, "onReady", null);
    AppAdjustLineImage = __decorate([
        Component({ components: { AppButton: AppButton } })
    ], AppAdjustLineImage);
    return AppAdjustLineImage;
}(Vue));
export default AppAdjustLineImage;

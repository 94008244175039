var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import PopperSelectMixin from './Mixins/PopperSelectMixin.vue';
import { AppIcon } from 'vue2/components';
import { isMobileDevice } from 'vue2/helpers';
var AppSelectSingle = /** @class */ (function (_super) {
    __extends(AppSelectSingle, _super);
    function AppSelectSingle() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isMobileDevice = isMobileDevice();
        return _this;
    }
    AppSelectSingle.prototype.onClickMobileSelect = function () {
        this.isMobileDevice = isMobileDevice();
    };
    AppSelectSingle.prototype.onChangeMobile = function (event) {
        var value = event.target.value;
        var selected = this.options.find(function (_a) {
            var code = _a.code;
            return value === code;
        });
        this.inputChanged(selected);
    };
    AppSelectSingle.prototype.inputChanged = function (selectedData) {
        return selectedData;
    };
    AppSelectSingle.prototype.created = function () {
        if (this.preselectFirst && !this.value) {
            this.inputChanged(this.options[0]);
        }
    };
    __decorate([
        Prop()
    ], AppSelectSingle.prototype, "value", void 0);
    __decorate([
        Prop({ default: function () { return []; } })
    ], AppSelectSingle.prototype, "options", void 0);
    __decorate([
        Prop({ default: 'Sorry, no matching options.' })
    ], AppSelectSingle.prototype, "noOptionsText", void 0);
    __decorate([
        Prop({ default: false })
    ], AppSelectSingle.prototype, "clearable", void 0);
    __decorate([
        Prop({ default: true })
    ], AppSelectSingle.prototype, "preselectFirst", void 0);
    __decorate([
        Prop()
    ], AppSelectSingle.prototype, "dropdownIcon", void 0);
    __decorate([
        Prop()
    ], AppSelectSingle.prototype, "disabled", void 0);
    __decorate([
        Emit('input')
    ], AppSelectSingle.prototype, "inputChanged", null);
    AppSelectSingle = __decorate([
        Component({ components: { AppIcon: AppIcon } })
    ], AppSelectSingle);
    return AppSelectSingle;
}(Mixins(PopperSelectMixin)));
export default AppSelectSingle;

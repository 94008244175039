//TODO add tooltip prop for button
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import AppImage from './AppImage.vue';
import { AppIconButton } from '../Button';
var AppImageRotate = /** @class */ (function (_super) {
    __extends(AppImageRotate, _super);
    function AppImageRotate() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.internalRotate = 0;
        return _this;
    }
    Object.defineProperty(AppImageRotate.prototype, "rotateStyle", {
        get: function () {
            return {
                transform: "rotate(".concat(this.internalRotate, "deg)"),
            };
        },
        enumerable: false,
        configurable: true
    });
    AppImageRotate.prototype.onImageClick = function () {
        return;
    };
    AppImageRotate.prototype.rotateImage = function () {
        this.internalRotate = this.internalRotate + 90;
        return { src: this.src || this.bgImage.src, rotate: this.internalRotate };
    };
    AppImageRotate.prototype.rotateChanged = function () {
        this.internalRotate = this.rotate;
    };
    __decorate([
        Prop()
    ], AppImageRotate.prototype, "src", void 0);
    __decorate([
        Prop()
    ], AppImageRotate.prototype, "alt", void 0);
    __decorate([
        Prop()
    ], AppImageRotate.prototype, "bgImage", void 0);
    __decorate([
        Prop()
    ], AppImageRotate.prototype, "fluid", void 0);
    __decorate([
        Prop({ default: false })
    ], AppImageRotate.prototype, "useDropshadow", void 0);
    __decorate([
        Prop()
    ], AppImageRotate.prototype, "loadingRotate", void 0);
    __decorate([
        Prop()
    ], AppImageRotate.prototype, "rotate", void 0);
    __decorate([
        Emit('onImageClick')
    ], AppImageRotate.prototype, "onImageClick", null);
    __decorate([
        Emit('onRotate')
    ], AppImageRotate.prototype, "rotateImage", null);
    __decorate([
        Watch('rotate')
    ], AppImageRotate.prototype, "rotateChanged", null);
    AppImageRotate = __decorate([
        Component({ components: { AppImage: AppImage, AppIconButton: AppIconButton } })
    ], AppImageRotate);
    return AppImageRotate;
}(Vue));
export default AppImageRotate;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { getWarehouse } from 'main/api';
import { defaultPaymentMethods } from './config';
export var mainModuleName = 'main';
var MainStore = /** @class */ (function (_super) {
    __extends(MainStore, _super);
    function MainStore() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.warehouse = null;
        _this.existingQuotes = [];
        _this.pickUpItems = [];
        _this.paymentMethods = defaultPaymentMethods;
        _this.categories = [];
        return _this;
    }
    MainStore.prototype.ADD_WAREHOUSE = function (warehouse) {
        this.warehouse = warehouse;
    };
    MainStore.prototype.REPLACE_QUOTES = function (quotes) {
        this.existingQuotes = __spreadArray([], quotes, true);
    };
    MainStore.prototype.REPLACE_PICKUP_ITEMS = function (pickUpItems) {
        this.pickUpItems = __spreadArray([], pickUpItems, true);
    };
    MainStore.prototype.SET_PAYMENT_METHODS = function () {
        this.paymentMethods = __spreadArray([], defaultPaymentMethods, true);
    };
    MainStore.prototype.SET_CATEGORIES = function (newCategories) {
        this.categories = __spreadArray([], newCategories, true);
    };
    MainStore.prototype.getWarehouse = function (warehouseId) {
        return __awaiter(this, void 0, void 0, function () {
            var warehouse, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getWarehouse(warehouseId)];
                    case 1:
                        warehouse = _a.sent();
                        this.ADD_WAREHOUSE(warehouse);
                        return [2 /*return*/, warehouse];
                    case 2:
                        error_1 = _a.sent();
                        this.ADD_WAREHOUSE(null);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MainStore.prototype.reset = function () {
        this.ADD_WAREHOUSE(null);
        this.REPLACE_QUOTES([]);
        this.SET_PAYMENT_METHODS();
    };
    __decorate([
        Mutation
    ], MainStore.prototype, "ADD_WAREHOUSE", null);
    __decorate([
        Mutation
    ], MainStore.prototype, "REPLACE_QUOTES", null);
    __decorate([
        Mutation
    ], MainStore.prototype, "REPLACE_PICKUP_ITEMS", null);
    __decorate([
        Mutation
    ], MainStore.prototype, "SET_PAYMENT_METHODS", null);
    __decorate([
        Mutation
    ], MainStore.prototype, "SET_CATEGORIES", null);
    __decorate([
        Action
    ], MainStore.prototype, "getWarehouse", null);
    __decorate([
        Action
    ], MainStore.prototype, "reset", null);
    MainStore = __decorate([
        Module({ namespaced: true, name: mainModuleName })
    ], MainStore);
    return MainStore;
}(VuexModule));
export default MainStore;

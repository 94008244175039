var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { AppIcon } from '../Icon';
var AppPillInput = /** @class */ (function (_super) {
    __extends(AppPillInput, _super);
    function AppPillInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.focused = false;
        return _this;
    }
    Object.defineProperty(AppPillInput.prototype, "pillStyle", {
        get: function () {
            return {
                backgroundColor: !this.disabled ? this.backgroundColor : '',
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppPillInput.prototype, "pillClass", {
        get: function () {
            return {
                disabled: this.disabled,
                focused: this.focused,
            };
        },
        enumerable: false,
        configurable: true
    });
    AppPillInput.prototype.onCloseClick = function () {
        this.onChange({ target: { value: '' } });
        return;
    };
    AppPillInput.prototype.onChange = function (e) {
        return e.target.value;
    };
    AppPillInput.prototype.onFocus = function () {
        this.focused = true;
        return;
    };
    AppPillInput.prototype.onLeave = function () {
        this.focused = false;
    };
    AppPillInput.prototype.onEnterPress = function (e) {
        return e.target.value;
    };
    __decorate([
        Prop()
    ], AppPillInput.prototype, "value", void 0);
    __decorate([
        Prop({ default: 'magnifying-glass' })
    ], AppPillInput.prototype, "icon", void 0);
    __decorate([
        Prop()
    ], AppPillInput.prototype, "disabled", void 0);
    __decorate([
        Prop()
    ], AppPillInput.prototype, "placeholder", void 0);
    __decorate([
        Prop({ default: 'white' })
    ], AppPillInput.prototype, "backgroundColor", void 0);
    __decorate([
        Prop({ default: true })
    ], AppPillInput.prototype, "closeIcon", void 0);
    __decorate([
        Emit('onCloseClick')
    ], AppPillInput.prototype, "onCloseClick", null);
    __decorate([
        Emit('input')
    ], AppPillInput.prototype, "onChange", null);
    __decorate([
        Emit('onFocus')
    ], AppPillInput.prototype, "onFocus", null);
    __decorate([
        Emit('onLeave')
    ], AppPillInput.prototype, "onLeave", null);
    __decorate([
        Emit('onEnterPress')
    ], AppPillInput.prototype, "onEnterPress", null);
    AppPillInput = __decorate([
        Component({ components: { AppIcon: AppIcon } })
    ], AppPillInput);
    return AppPillInput;
}(Vue));
export default AppPillInput;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AppSelectShelfMultiple from './AppSelectShelfMultiple.vue';
import AppSelectShelfSingle from './AppSelectShelfSingle.vue';
import isEmpty from 'lodash/isEmpty';
var AppSelectShelf = /** @class */ (function (_super) {
    __extends(AppSelectShelf, _super);
    function AppSelectShelf() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(AppSelectShelf.prototype, "selectComponent", {
        get: function () {
            if (this.multiple)
                return AppSelectShelfMultiple;
            return AppSelectShelfSingle;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppSelectShelf.prototype, "images", {
        get: function () {
            return this.options.map(function (_a) {
                var image = _a.image;
                return image;
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppSelectShelf.prototype, "hasImages", {
        get: function () {
            return !isEmpty(this.images.filter(function (img) { return img; }));
        },
        enumerable: false,
        configurable: true
    });
    AppSelectShelf.prototype.close = function (value) {
        return value;
    };
    AppSelectShelf.prototype.onClickFinish = function () {
        return;
    };
    AppSelectShelf.prototype.input = function (data) {
        return data;
    };
    AppSelectShelf.prototype.remove = function (data) {
        return data;
    };
    AppSelectShelf.prototype.reset = function () {
        return;
    };
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], AppSelectShelf.prototype, "value", void 0);
    __decorate([
        Prop({ default: function () { return []; } })
    ], AppSelectShelf.prototype, "options", void 0);
    __decorate([
        Prop({ default: 'Sorry, no matching options.' })
    ], AppSelectShelf.prototype, "noOptionsText", void 0);
    __decorate([
        Prop({ default: false })
    ], AppSelectShelf.prototype, "preselectFirst", void 0);
    __decorate([
        Prop()
    ], AppSelectShelf.prototype, "multiple", void 0);
    __decorate([
        Prop()
    ], AppSelectShelf.prototype, "disabled", void 0);
    __decorate([
        Prop()
    ], AppSelectShelf.prototype, "removeable", void 0);
    __decorate([
        Prop()
    ], AppSelectShelf.prototype, "autoOpen", void 0);
    __decorate([
        Prop({ default: 'No items selected' })
    ], AppSelectShelf.prototype, "noSelectText", void 0);
    __decorate([
        Prop()
    ], AppSelectShelf.prototype, "minWidthImage", void 0);
    __decorate([
        Emit('close')
    ], AppSelectShelf.prototype, "close", null);
    __decorate([
        Emit('onClickFinish')
    ], AppSelectShelf.prototype, "onClickFinish", null);
    __decorate([
        Emit('input')
    ], AppSelectShelf.prototype, "input", null);
    __decorate([
        Emit('remove')
    ], AppSelectShelf.prototype, "remove", null);
    __decorate([
        Emit('reset')
    ], AppSelectShelf.prototype, "reset", null);
    AppSelectShelf = __decorate([
        Component({ components: {} })
    ], AppSelectShelf);
    return AppSelectShelf;
}(Vue));
export default AppSelectShelf;

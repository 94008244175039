var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Mixins } from "vue-property-decorator";
import AppImage from "./AppImage.vue";
import AppImageLightbox from "./Lightbox/AppImageLightbox.vue";
import isEmpty from "lodash/isEmpty";
import ResizeMixin from "../../mixins/resize";
import debounce from "lodash/debounce";
var AppImageHover = /** @class */ (function (_super) {
    __extends(AppImageHover, _super);
    function AppImageHover() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.refImageName = "refImageName";
        _this.isHovering = false;
        _this.imageOffsetWidth = 100;
        _this.hoverImage = "";
        _this.openLightbox = false;
        _this.numberOfImagesLoaded = 0;
        return _this;
    }
    Object.defineProperty(AppImageHover.prototype, "hasImages", {
        get: function () {
            return !isEmpty(this.images);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppImageHover.prototype, "internalBgImage", {
        get: function () {
            var _this = this;
            var _a;
            return __assign(__assign({}, this.bgImage), { src: this.isHovering ? this.hoverImage : (_a = this.bgImage) === null || _a === void 0 ? void 0 : _a.src, bgColor: "var(--white-color)", onClick: function () {
                    var _a, _b;
                    (_b = (_a = _this.bgImage) === null || _a === void 0 ? void 0 : _a.onClick) === null || _b === void 0 ? void 0 : _b.call(_a);
                    _this.toggleLightbox(true);
                } });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppImageHover.prototype, "numberOfImages", {
        get: function () {
            var _a;
            return ((_a = this.images) === null || _a === void 0 ? void 0 : _a.length) || 1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppImageHover.prototype, "percentIncrements", {
        get: function () {
            return 100 / this.numberOfImages;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppImageHover.prototype, "loadingStyle", {
        get: function () {
            return {
                width: "".concat((this.numberOfImagesLoaded / this.numberOfImages) * 100, "%"),
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppImageHover.prototype, "hideLoader", {
        get: function () {
            return this.loadingStyle.width === "100%";
        },
        enumerable: false,
        configurable: true
    });
    AppImageHover.prototype.onLoadedImage = function () {
        this.numberOfImagesLoaded++;
    };
    AppImageHover.prototype.toggleLightbox = function (value) {
        this.openLightbox = value;
    };
    AppImageHover.prototype.mouseEnter = function (e) {
        this.isHovering = true;
    };
    AppImageHover.prototype.mouseMove = function (e) {
        var percentMoved = (e.offsetX / this.imageOffsetWidth) * 100;
        var imageIndex = Math.round(percentMoved / this.percentIncrements);
        this.hoverImage =
            this.images[imageIndex] || this.images[0] || this.bgImage.src;
    };
    AppImageHover.prototype.mouseLeave = function (e) {
        this.isHovering = false;
    };
    Object.defineProperty(AppImageHover.prototype, "setOffsetWidth", {
        get: function () {
            var _this = this;
            return debounce(function () {
                var refElement = _this.$refs[_this.refImageName];
                _this.imageOffsetWidth = refElement === null || refElement === void 0 ? void 0 : refElement.offsetWidth;
            }, 1000);
        },
        enumerable: false,
        configurable: true
    });
    AppImageHover.prototype.mounted = function () {
        this.observeResize(this.$el, this.setOffsetWidth);
    };
    __decorate([
        Prop()
    ], AppImageHover.prototype, "bgImage", void 0);
    __decorate([
        Prop({ default: function () { return []; } })
    ], AppImageHover.prototype, "images", void 0);
    __decorate([
        Prop({ default: true })
    ], AppImageHover.prototype, "useLightbox", void 0);
    __decorate([
        Prop({ default: true })
    ], AppImageHover.prototype, "useLoader", void 0);
    AppImageHover = __decorate([
        Component({ components: { AppImage: AppImage, AppImageLightbox: AppImageLightbox } })
    ], AppImageHover);
    return AppImageHover;
}(Mixins(ResizeMixin)));
export default AppImageHover;

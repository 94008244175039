var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import isEmpty from 'lodash/isEmpty';
export var userModuleName = 'user';
import { loginWithWebApp, loginWithFashionphile } from 'main/api';
var UserModule = /** @class */ (function (_super) {
    __extends(UserModule, _super);
    function UserModule() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.userResponse = null;
        _this.webAppToken = '';
        return _this;
    }
    Object.defineProperty(UserModule.prototype, "isLoggedIn", {
        get: function () {
            return !isEmpty(this.userResponse) && !!this.userAuthToken;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserModule.prototype, "userAuthToken", {
        get: function () {
            var _a;
            return (_a = this.userResponse) === null || _a === void 0 ? void 0 : _a.meta.token;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UserModule.prototype, "userData", {
        get: function () {
            var _a;
            return (_a = this.userResponse) === null || _a === void 0 ? void 0 : _a.data;
        },
        enumerable: false,
        configurable: true
    });
    UserModule.prototype.SET_USER = function (user) {
        this.userResponse = user;
    };
    UserModule.prototype.SET_WEBAPP_TOKEN = function (token) {
        this.webAppToken = token;
    };
    UserModule.prototype.loginWithFashionphile = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var fashionphileResponse, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Promise.all([loginWithFashionphile(params), this.loginWithWebApp(params)])];
                    case 1:
                        fashionphileResponse = (_a.sent())[0];
                        this.SET_USER(fashionphileResponse);
                        return [2 /*return*/, fashionphileResponse];
                    case 2:
                        error_1 = _a.sent();
                        return [2 /*return*/, Promise.reject(error_1)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserModule.prototype.loginWithWebApp = function (_a) {
        var email = _a.email, password = _a.password;
        return __awaiter(this, void 0, void 0, function () {
            var token, error_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, loginWithWebApp({
                                email: email,
                                password: password,
                            })];
                    case 1:
                        token = (_b.sent()).token;
                        this.SET_WEBAPP_TOKEN(token);
                        return [2 /*return*/, token];
                    case 2:
                        error_2 = _b.sent();
                        return [2 /*return*/, Promise.reject(error_2)];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    UserModule.prototype.logout = function () {
        this.SET_USER(null);
        this.SET_WEBAPP_TOKEN(null);
    };
    __decorate([
        Mutation
    ], UserModule.prototype, "SET_USER", null);
    __decorate([
        Mutation
    ], UserModule.prototype, "SET_WEBAPP_TOKEN", null);
    __decorate([
        Action({ rawError: true })
    ], UserModule.prototype, "loginWithFashionphile", null);
    __decorate([
        Action({ rawError: true })
    ], UserModule.prototype, "loginWithWebApp", null);
    __decorate([
        Action
    ], UserModule.prototype, "logout", null);
    UserModule = __decorate([
        Module({ namespaced: true, name: userModuleName })
    ], UserModule);
    return UserModule;
}(VuexModule));
export default UserModule;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { storeModules } from 'main/store';
import { getBuildMessagesById } from './findMessages';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
export var toggleLoadingProp = function (currentMessageId) {
    var oldMessage = getBuildMessagesById(currentMessageId);
    var newMessage = __assign(__assign({}, oldMessage), { props: __assign(__assign({}, oldMessage.props), { loading: !oldMessage.props.loading }) });
    findAndReplaceDisplayedMessage(currentMessageId, newMessage);
};
export var findAndReplaceDisplayedMessage = function (currentMessageId, newMessage) {
    var messages = __spreadArray([], storeModules.showroomAssistant.displayedMessages, true);
    var match = find(messages, function (message) { return message.id === currentMessageId; });
    if (match) {
        var index = findIndex(messages, function (message) { return message.id === currentMessageId; });
        messages[index] = newMessage;
        storeModules.showroomAssistant.REPLACE_DISPLAYED_MESSAGES(messages);
    }
};

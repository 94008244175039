var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { ChartTypes, } from './interfaces';
import { registerChart } from './helpers';
import { v4 as uuidv4 } from 'uuid';
//@ts-ignore
import { Bar, Line, Doughnut, Pie } from 'vue-chartjs/legacy';
var AppChart = /** @class */ (function (_super) {
    __extends(AppChart, _super);
    function AppChart() {
        var _a;
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.componentConfig = (_a = {},
            _a[ChartTypes.Line] = Line,
            _a[ChartTypes.Bar] = Bar,
            _a[ChartTypes.Doughnut] = Doughnut,
            _a[ChartTypes.Pie] = Pie,
            _a);
        _this.chartId = uuidv4();
        _this.chartComponent = null;
        return _this;
    }
    AppChart.prototype.onUpdated = function () {
        return;
    };
    AppChart.prototype.onRendered = function () {
        return;
    };
    AppChart.prototype.onDestroyed = function () {
        return;
    };
    AppChart.prototype.created = function () {
        this.renderChart();
    };
    AppChart.prototype.renderChart = function () {
        registerChart(this.chartType);
        this.chartId = uuidv4();
        this.chartComponent = this.componentConfig[this.chartType];
    };
    __decorate([
        Prop()
    ], AppChart.prototype, "chartData", void 0);
    __decorate([
        Prop({
            default: function () { return ({
                responsive: true,
                maintainAspectRatio: false,
            }); },
        })
    ], AppChart.prototype, "chartOptions", void 0);
    __decorate([
        Prop({ default: ChartTypes.Bar })
    ], AppChart.prototype, "chartType", void 0);
    __decorate([
        Prop()
    ], AppChart.prototype, "width", void 0);
    __decorate([
        Prop({ default: 300 })
    ], AppChart.prototype, "height", void 0);
    __decorate([
        Emit('onUpdated')
    ], AppChart.prototype, "onUpdated", null);
    __decorate([
        Emit('onRendered')
    ], AppChart.prototype, "onRendered", null);
    __decorate([
        Emit('onDestroyed')
    ], AppChart.prototype, "onDestroyed", null);
    __decorate([
        Watch('height'),
        Watch('width')
    ], AppChart.prototype, "renderChart", null);
    AppChart = __decorate([
        Component({ components: {} })
    ], AppChart);
    return AppChart;
}(Vue));
export default AppChart;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Component, Vue, Prop, Ref, Watch, Emit } from 'vue-property-decorator';
import Muuri from 'muuri';
import { defaultMuuriConfig, disableClickBubbleClass, disableDragClass } from './config';
import { classIsFoundInParent } from 'vue2/helpers';
import isEqual from 'lodash/isEqual';
var muuriKeyAttribute = 'muurikey';
var AppMuuriGridSlotted = /** @class */ (function (_super) {
    __extends(AppMuuriGridSlotted, _super);
    function AppMuuriGridSlotted() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.grid = null;
        _this.disableDragClass = disableDragClass;
        _this.oldOrder = [];
        return _this;
    }
    Object.defineProperty(AppMuuriGridSlotted.prototype, "_options", {
        get: function () {
            var _this = this;
            return __assign(__assign(__assign({}, defaultMuuriConfig), { dragSortPredicate: function (item) {
                    var result = Muuri.ItemDrag.defaultSortPredicate(item);
                    var itemId = _this.getOrder()[result === null || result === void 0 ? void 0 : result.index];
                    var matchingAppMurriItem = _this.findItemById(itemId);
                    return (matchingAppMurriItem === null || matchingAppMurriItem === void 0 ? void 0 : matchingAppMurriItem.preventFromBeingSorted) ? false : result;
                } }), this.options);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppMuuriGridSlotted.prototype, "items", {
        get: function () {
            return this.value;
        },
        set: function (newVal) {
            this.$emit('input', newVal);
        },
        enumerable: false,
        configurable: true
    });
    AppMuuriGridSlotted.prototype.findItemById = function (itemId) {
        return this.value.find(function (_a) {
            var id = _a.id;
            return id == Number(itemId);
        });
    };
    AppMuuriGridSlotted.prototype.layout = function () {
        if (this.grid) {
            this.grid.refreshItems();
            this.grid.layout();
        }
    };
    AppMuuriGridSlotted.prototype.getOrder = function () {
        var _this = this;
        if (this.grid) {
            return this.grid
                .getItems()
                .filter(function (item) { return item.isVisible(); })
                .map(function (item) { return _this.getItemId(item); });
        }
        else
            return [];
    };
    AppMuuriGridSlotted.prototype.getItemId = function (item) {
        var _a;
        return Number((_a = item.getElement()) === null || _a === void 0 ? void 0 : _a.getAttribute(muuriKeyAttribute));
    };
    AppMuuriGridSlotted.prototype.getMatchingMuuriItem = function (item) {
        var itemId = this.getItemId(item);
        return this.value.find(function (_a) {
            var id = _a.id;
            return id === itemId;
        });
    };
    AppMuuriGridSlotted.prototype.updateData = function () {
        var _this = this;
        // If there's no grid, there's nothing to model the data after
        if (!this.grid)
            return;
        // Get data order by keys
        var order = this.getOrder();
        // Use keys to sort data
        this.items.sort(function (a, b) { return order.indexOf(a[_this.muurikey]) - order.indexOf(b[_this.muurikey]); });
        if (!isEqual(this.oldOrder, order))
            this.onChangeGridOrder();
        this.oldOrder = order;
    };
    AppMuuriGridSlotted.prototype.forceSortItemsByIndex = function () {
        var _this = this;
        if (!this.grid)
            return;
        var newOrder = this.items.map(function (field) { return field[_this.muurikey]; });
        this.grid.sort(function (a, b) {
            return newOrder.indexOf(_this.getItemId(a)) - newOrder.indexOf(_this.getItemId(b));
        });
    };
    AppMuuriGridSlotted.prototype.onChangeGridOrder = function () {
        return this.getOrder();
    };
    AppMuuriGridSlotted.prototype.onGridDestroy = function () {
        return;
    };
    AppMuuriGridSlotted.prototype.onShowGridEnd = function () {
        return;
    };
    AppMuuriGridSlotted.prototype.onLayoutEnd = function () {
        return;
    };
    AppMuuriGridSlotted.prototype.dragEndHandler = function (item, event) {
        if (event.distance > this.dragClickDistance) {
            this.updateData();
            this.$emit('onDragEnd', this.getItemId(item));
        }
        else
            this.onClickItem(item, event.srcEvent);
    };
    AppMuuriGridSlotted.prototype.dragMoveHandler = function (item, event) {
        if (event.distance > this.dragClickDistance) {
            this.$emit('onIsDragging', this.getItemId(item));
        }
    };
    AppMuuriGridSlotted.prototype.onClickItem = function (item, event) {
        var doNotSendEmit = classIsFoundInParent(event.target, disableClickBubbleClass);
        !doNotSendEmit && this.$emit('onClickItem', { event: event, item: this.getMatchingMuuriItem(item) });
    };
    AppMuuriGridSlotted.prototype.beforeDestroy = function () {
        if (this.grid) {
            try {
                this.grid.destroy();
            }
            catch (error) {
                this.$_toast_error({ message: 'Grid was not fully destroyed' }, error);
            }
        }
    };
    AppMuuriGridSlotted.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var error_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!(this._options !== null)) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        if (!!this.muuriel) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.$nextTick()];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 1];
                    case 3:
                        if (this.grid)
                            this.grid.destroy();
                        this.grid = new Muuri(this.muuriel, this._options);
                        this.grid.on('showEnd', function () { return _this.onShowGridEnd(); });
                        this.grid.on('layoutEnd', function () { return _this.onLayoutEnd(); });
                        this.grid.on('destroy', function () { return _this.$emit('onDestroy'); });
                        this.grid.on('dragEnd', function (item, event) { return _this.dragEndHandler(item, event); });
                        this.grid.on('dragMove', function (item, event) { return _this.dragMoveHandler(item, event); });
                        this.oldOrder = this.getOrder();
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        error_1 = _a.sent();
                        console.error(error_1);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    AppMuuriGridSlotted.prototype.updateGrid = function (newData, oldData) {
        return __awaiter(this, void 0, void 0, function () {
            var oldKeys, newKeys, added, removed;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // If there's no grid, we don't need to update it
                        if (!this.grid)
                            return [2 /*return*/];
                        oldKeys = oldData.map(function (e) { return e[_this.muurikey]; });
                        newKeys = newData.map(function (e) { return e[_this.muurikey]; });
                        added = newKeys.filter(function (key) { return oldKeys.indexOf(key) === -1; });
                        removed = oldKeys.filter(function (key) { return newKeys.indexOf(key) === -1; });
                        // Deal with the removed tiles first
                        removed.forEach(function (itemId) {
                            var _a;
                            var removeItemIndex = _this.getOrder().indexOf(itemId);
                            var removeItem = (_a = _this.grid) === null || _a === void 0 ? void 0 : _a.getItem(removeItemIndex);
                            _this.grid.remove([removeItem], {
                                // Vue will take care of this
                                removeElements: false,
                                // We will be adding items too, so not quite yet
                                layout: false,
                            });
                        });
                        return [4 /*yield*/, this.$nextTick()];
                    case 1:
                        _a.sent();
                        // Now add in the new ones
                        this.grid.add(__spreadArray([], this.muuriel.childNodes, true)
                            // Filter out anything not in list of added orders
                            .filter(function (el) { return added.indexOf(Number(el.getAttribute(muuriKeyAttribute))) !== -1; }), {
                            // We will be re-ordering, so wait a bit longer
                            layout: false,
                        });
                        // Re-order if needed
                        this.autoSortItemsOnChange && this.forceSortItemsByIndex();
                        // Now we can start the layout
                        this.layout();
                        return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        Prop()
    ], AppMuuriGridSlotted.prototype, "muurikey", void 0);
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], AppMuuriGridSlotted.prototype, "options", void 0);
    __decorate([
        Prop()
    ], AppMuuriGridSlotted.prototype, "value", void 0);
    __decorate([
        Prop({ default: 5 })
    ], AppMuuriGridSlotted.prototype, "dragClickDistance", void 0);
    __decorate([
        Prop({ default: false })
    ], AppMuuriGridSlotted.prototype, "autoSortItemsOnChange", void 0);
    __decorate([
        Prop()
    ], AppMuuriGridSlotted.prototype, "draggingXItems", void 0);
    __decorate([
        Ref()
    ], AppMuuriGridSlotted.prototype, "muuriel", void 0);
    __decorate([
        Emit('onChangeGridOrder')
    ], AppMuuriGridSlotted.prototype, "onChangeGridOrder", null);
    __decorate([
        Emit('destroy')
    ], AppMuuriGridSlotted.prototype, "onGridDestroy", null);
    __decorate([
        Emit('showEnd')
    ], AppMuuriGridSlotted.prototype, "onShowGridEnd", null);
    __decorate([
        Emit('layoutEnd')
    ], AppMuuriGridSlotted.prototype, "onLayoutEnd", null);
    __decorate([
        Watch('_options', { immediate: true })
    ], AppMuuriGridSlotted.prototype, "init", null);
    __decorate([
        Watch('items', { deep: true, immediate: true })
    ], AppMuuriGridSlotted.prototype, "updateGrid", null);
    AppMuuriGridSlotted = __decorate([
        Component({})
    ], AppMuuriGridSlotted);
    return AppMuuriGridSlotted;
}(Vue));
export default AppMuuriGridSlotted;

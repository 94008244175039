var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Emit, Watch } from "vue-property-decorator";
import { APP_ID } from "../../../main/config";
import { AppIconButton } from "../Button";
var BasicOverlay = /** @class */ (function (_super) {
    __extends(BasicOverlay, _super);
    function BasicOverlay() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BasicOverlay.prototype.onExitClick = function () {
        return true;
    };
    BasicOverlay.prototype.isOpenChanged = function () {
        var appElement = document.getElementById(this.APP_ID);
        var styleOfOverflow = this.isOpen ? "hidden" : "initial";
        //@ts-ignore
        appElement.style["overflow"] = styleOfOverflow;
    };
    __decorate([
        Prop({ default: true })
    ], BasicOverlay.prototype, "useExitButton", void 0);
    __decorate([
        Prop()
    ], BasicOverlay.prototype, "isOpen", void 0);
    __decorate([
        Prop({ default: APP_ID })
    ], BasicOverlay.prototype, "APP_ID", void 0);
    __decorate([
        Emit("onExitClick")
    ], BasicOverlay.prototype, "onExitClick", null);
    __decorate([
        Watch("isOpen")
    ], BasicOverlay.prototype, "isOpenChanged", null);
    BasicOverlay = __decorate([
        Component({ components: { AppIconButton: AppIconButton } })
    ], BasicOverlay);
    return BasicOverlay;
}(Vue));
export default BasicOverlay;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import { AppIconButton } from '../Button';
import AppTextInput from './AppTextInput.vue';
var AppToggleInput = /** @class */ (function (_super) {
    __extends(AppToggleInput, _super);
    function AppToggleInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(AppToggleInput.prototype, "dynamicClass", {
        get: function () {
            var _a;
            return _a = {},
                _a[this.type] = true,
                _a.disabled = this.disabled,
                _a;
        },
        enumerable: false,
        configurable: true
    });
    AppToggleInput.prototype.onIncrement = function () {
        if (!this.disabled) {
            this.$emit('onIncrement', true);
        }
    };
    AppToggleInput.prototype.onDecrement = function () {
        if (!this.disabled) {
            this.$emit('onDecrement', false);
        }
    };
    __decorate([
        Prop()
    ], AppToggleInput.prototype, "value", void 0);
    __decorate([
        Prop({ default: 'dark' })
    ], AppToggleInput.prototype, "type", void 0);
    __decorate([
        Prop()
    ], AppToggleInput.prototype, "disabled", void 0);
    __decorate([
        Prop({ default: 'center' })
    ], AppToggleInput.prototype, "showValuePosition", void 0);
    AppToggleInput = __decorate([
        Component({
            components: { AppIconButton: AppIconButton, AppTextInput: AppTextInput },
        })
    ], AppToggleInput);
    return AppToggleInput;
}(Vue));
export default AppToggleInput;

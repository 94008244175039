var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-crop-image",style:({ height: (_vm.height + "px"), width: (_vm.width + "px") })},[(_vm.imageUrl)?_c('Cropper',{key:_vm.height + '_' + _vm.width,ref:"cropper",style:(_vm.cropperStyle),attrs:{"src":_vm.imageUrl,"canvas":true,"stencil-props":{
      aspectRatio: _vm.aspectRatio,
      linesWrappersClasses: {
        north: _vm.applyStencilClasses ? 'app-crop-image__cropper-top-wrapper' : undefined,
        east: _vm.applyStencilClasses ? 'app-crop-image__cropper-right-wrapper' : undefined,
        south: _vm.applyStencilClasses ? 'app-crop-image__cropper-bottom-wrapper' : undefined,
        west: _vm.applyStencilClasses ? 'app-crop-image__cropper-left-wrapper' : undefined,
      },
      linesClasses: {
        north: _vm.applyStencilClasses ? 'app-crop-image__cropper-top-line' : undefined,
        east: _vm.applyStencilClasses ? 'app-crop-image__cropper-right-line' : undefined,
        south: _vm.applyStencilClasses ? 'app-crop-image__cropper-bottom-line' : undefined,
        west: _vm.applyStencilClasses ? 'app-crop-image__cropper-left-line' : undefined,
      },
      movingClass: _vm.applyStencilClasses ? "app-crop-image__cropper-moving" : undefined,
    },"transitions":false,"transitionTime":0},on:{"change":_vm.onChange,"ready":_vm.onReady,"error":_vm.onError}}):_vm._e(),_vm._v(" "),_c('canvas',{ref:"editor",staticClass:"editor"}),_vm._v(" "),(!_vm.loaded)?_c('div',{staticClass:"loader-container"},[_c('div',{staticClass:"loader"},[_c('AppBasicSpinnerLoader')],1)]):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loaded),expression:"loaded"}],staticClass:"app-crop-image__button-container"},[_c('div',{staticClass:"d-flex rotate-slider"},[_c('AppSliderInput',{staticClass:"rotate-slider__slider",attrs:{"min":-180,"max":180,"interval":1,"marks":false,"hideLabel":true,"disabled":_vm.loading},on:{"input":_vm.onDragSlider},model:{value:(_vm.rotateAmount),callback:function ($$v) {_vm.rotateAmount=$$v},expression:"rotateAmount"}}),_vm._v(" "),_c('div',{staticClass:"rotate-slider__rotate-button ml-2 mr-1"},[_c('AppIcon',{attrs:{"icon":"rotate"},on:{"onClick":function($event){return _vm.onRotateClick()}}})],1)],1),_vm._v(" "),_c('div',{staticClass:"button-row d-flex w-100"},[_c('AppButton',{attrs:{"text":"Cancel","type":"gray","disabled":_vm.disableCancel},on:{"onClick":_vm.onCancel}}),_vm._v(" "),_c('AppButton',{staticClass:"ml-2 submit",attrs:{"text":"Crop","disabled":_vm.disableSubmit,"type":"blue","block":true,"loading":_vm.loading},on:{"onClick":_vm.onSave}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import { buttonTypeConfig, buttonSizeConfig, ButtonTypes, ButtonSizes } from './config';
import { AppIcon } from '../Icon';
import AppBasicSpinnerLoader from '../Loader/AppBasicSpinnerLoader.vue';
var AppIconButton = /** @class */ (function (_super) {
    __extends(AppIconButton, _super);
    function AppIconButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(AppIconButton.prototype, "dynamicClass", {
        get: function () {
            var _a;
            return _a = {},
                _a[buttonSizeConfig[this.size]] = true,
                _a[buttonTypeConfig[this.type]] = true,
                _a.loading = this.loading,
                _a.round = this.round,
                _a['button-shadow'] = this.shadow,
                _a;
        },
        enumerable: false,
        configurable: true
    });
    AppIconButton.prototype.onClick = function () {
        if (!this.disabled) {
            this.$emit('onClick');
        }
    };
    __decorate([
        Prop({ default: 'times' })
    ], AppIconButton.prototype, "icon", void 0);
    __decorate([
        Prop({ default: false })
    ], AppIconButton.prototype, "disabled", void 0);
    __decorate([
        Prop({ default: false })
    ], AppIconButton.prototype, "loading", void 0);
    __decorate([
        Prop({ default: ButtonSizes.sm })
    ], AppIconButton.prototype, "size", void 0);
    __decorate([
        Prop({ default: ButtonTypes.glass })
    ], AppIconButton.prototype, "type", void 0);
    __decorate([
        Prop({ default: false })
    ], AppIconButton.prototype, "round", void 0);
    __decorate([
        Prop()
    ], AppIconButton.prototype, "shadow", void 0);
    AppIconButton = __decorate([
        Component({ components: { AppIcon: AppIcon, AppBasicSpinnerLoader: AppBasicSpinnerLoader } })
    ], AppIconButton);
    return AppIconButton;
}(Vue));
export default AppIconButton;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { videoPlayer as VideoPlayer } from 'vue-video-player';
import 'video.js/dist/video-js.css';
//@ts-ignore
var AppVideo = /** @class */ (function (_super) {
    __extends(AppVideo, _super);
    function AppVideo() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AppVideo.prototype.player = function () {
        //@ts-ignore
        return this.$refs.videoPlayer.player;
    };
    Object.defineProperty(AppVideo.prototype, "playerOptions", {
        // videojs options
        get: function () {
            return {
                controls: this.controls,
                autoplay: this.autoplay,
                loop: this.loop,
                muted: this.muted,
                language: 'en',
                playbackRates: [0.7, 1.0, 1.5, 2.0],
                sources: [
                    {
                        type: this.type,
                        src: this.source,
                    },
                ],
                poster: this.poster,
            };
        },
        enumerable: false,
        configurable: true
    });
    AppVideo.prototype.onPlayerPlay = function (player) {
        return;
    };
    AppVideo.prototype.onPlayerPause = function (player) {
        return;
    };
    AppVideo.prototype.onPlayerEnded = function (player) {
        return;
    };
    AppVideo.prototype.onPlayerLoadeddata = function (player) {
        return;
    };
    AppVideo.prototype.onPlayerWaiting = function (player) {
        return;
    };
    AppVideo.prototype.onPlayerPlaying = function (player) {
        return;
    };
    AppVideo.prototype.onPlayerTimeupdate = function (player) {
        return;
    };
    AppVideo.prototype.onPlayerCanplay = function (player) {
        return;
    };
    AppVideo.prototype.onPlayerCanplaythrough = function (player) {
        return;
    };
    // or listen state event
    AppVideo.prototype.playerStateChanged = function (playerCurrentState) {
        return;
    };
    // player is ready
    AppVideo.prototype.playerReadied = function (player) {
        return;
    };
    __decorate([
        Prop()
    ], AppVideo.prototype, "source", void 0);
    __decorate([
        Prop({ default: 'video/mp4' })
    ], AppVideo.prototype, "type", void 0);
    __decorate([
        Prop({ default: true })
    ], AppVideo.prototype, "autoplay", void 0);
    __decorate([
        Prop({ default: true })
    ], AppVideo.prototype, "muted", void 0);
    __decorate([
        Prop({ default: true })
    ], AppVideo.prototype, "playsinline", void 0);
    __decorate([
        Prop({ default: false })
    ], AppVideo.prototype, "loop", void 0);
    __decorate([
        Prop()
    ], AppVideo.prototype, "poster", void 0);
    __decorate([
        Prop({ default: false })
    ], AppVideo.prototype, "controls", void 0);
    __decorate([
        Prop()
    ], AppVideo.prototype, "fitToDiv", void 0);
    __decorate([
        Emit('onPlayerPlay')
    ], AppVideo.prototype, "onPlayerPlay", null);
    __decorate([
        Emit('onPlayerPause')
    ], AppVideo.prototype, "onPlayerPause", null);
    __decorate([
        Emit('onPlayerEnded')
    ], AppVideo.prototype, "onPlayerEnded", null);
    __decorate([
        Emit('onPlayerLoadeddata')
    ], AppVideo.prototype, "onPlayerLoadeddata", null);
    __decorate([
        Emit('onPlayerWaiting')
    ], AppVideo.prototype, "onPlayerWaiting", null);
    __decorate([
        Emit('onPlayerPlaying')
    ], AppVideo.prototype, "onPlayerPlaying", null);
    __decorate([
        Emit('onPlayerTimeupdate')
    ], AppVideo.prototype, "onPlayerTimeupdate", null);
    __decorate([
        Emit('onPlayerCanplay')
    ], AppVideo.prototype, "onPlayerCanplay", null);
    __decorate([
        Emit('onPlayerCanplaythrough')
    ], AppVideo.prototype, "onPlayerCanplaythrough", null);
    __decorate([
        Emit('playerStateChanged')
    ], AppVideo.prototype, "playerStateChanged", null);
    __decorate([
        Emit('playerReadied')
    ], AppVideo.prototype, "playerReadied", null);
    AppVideo = __decorate([
        Component({ components: { VideoPlayer: VideoPlayer } })
    ], AppVideo);
    return AppVideo;
}(Vue));
export default AppVideo;

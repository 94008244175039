var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { AppButton } from '../Button';
var FILE_TYPES = 'image/png, image/jpeg, image/bmp, application/pdf, text/html';
var AppFileInput = /** @class */ (function (_super) {
    __extends(AppFileInput, _super);
    function AppFileInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.uploadRefName = 'uploadRefName';
        return _this;
    }
    AppFileInput.prototype.onClick = function () {
        var target = this.$refs[this.uploadRefName];
        target.click();
    };
    AppFileInput.prototype.onUpload = function (_a) {
        var files = _a.target.files;
        return Object.values(files);
    };
    __decorate([
        Prop({ default: FILE_TYPES })
    ], AppFileInput.prototype, "accept", void 0);
    __decorate([
        Prop()
    ], AppFileInput.prototype, "multiple", void 0);
    __decorate([
        Prop()
    ], AppFileInput.prototype, "text", void 0);
    __decorate([
        Prop()
    ], AppFileInput.prototype, "block", void 0);
    __decorate([
        Prop()
    ], AppFileInput.prototype, "type", void 0);
    __decorate([
        Emit('onUpload')
    ], AppFileInput.prototype, "onUpload", null);
    AppFileInput = __decorate([
        Component({ components: { AppButton: AppButton } })
    ], AppFileInput);
    return AppFileInput;
}(Vue));
export default AppFileInput;

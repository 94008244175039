var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import Packery from 'packery';
import Draggabilly from 'draggabilly';
import isEmpty from 'lodash/isEmpty';
var gridClass = 'app-grid';
var itemClass = 'app-grid-item';
var AppDragGrid = /** @class */ (function (_super) {
    __extends(AppDragGrid, _super);
    function AppDragGrid() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.gridClass = gridClass;
        _this.itemClass = itemClass;
        _this.pckry = null;
        _this.dragElements = [];
        return _this;
    }
    AppDragGrid.prototype.onChangeDragItem = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.updateGridLayout()];
                    case 1:
                        _a.sent();
                        this.onChange();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppDragGrid.prototype.updateGridLayout = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.$nextTick()];
                    case 1:
                        _a.sent();
                        this.forceItemsHorizontal && this.pckry.layout();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppDragGrid.prototype.reinitializeGrid = function () {
        this.pckry.reloadItems();
    };
    AppDragGrid.prototype.initializeGrid = function () {
        return __awaiter(this, void 0, void 0, function () {
            var hasAppGridItems;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.$nextTick()];
                    case 1:
                        _a.sent();
                        hasAppGridItems = !isEmpty(this.$el.querySelectorAll(".".concat(this.itemClass)));
                        if (!hasAppGridItems)
                            return [2 /*return*/, console.warn('No app-grid items found')];
                        if (this.pckry) {
                            this.reinitializeGrid();
                            return [2 /*return*/];
                        }
                        this.pckry = new Packery(".".concat(this.gridClass), {
                            itemSelector: ".".concat(this.itemClass),
                            columnWidth: this.columnWidth,
                            rowHeight: this.rowHeight,
                            gutter: this.gutter,
                            horizontal: this.horizontal,
                            transitionDuration: '0.3s',
                        });
                        this.pckry.getItemElements().forEach(function (itemElem) {
                            var draggie = new Draggabilly(itemElem);
                            _this.pckry.bindDraggabillyEvents(draggie);
                            _this.dragElements.push(draggie);
                        });
                        this.pckry.on('dragItemPositioned', this.onChangeDragItem);
                        this.onChangeDisableDrag(this.disableDrag);
                        return [2 /*return*/];
                }
            });
        });
    };
    AppDragGrid.prototype.disableDragGrid = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.dragElements.forEach(function (draggie) { return draggie.disable(); });
                return [2 /*return*/];
            });
        });
    };
    AppDragGrid.prototype.enableDragGrid = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.dragElements.forEach(function (draggie) { return draggie.enable(); });
                return [2 /*return*/];
            });
        });
    };
    AppDragGrid.prototype.onChange = function () {
        return this.pckry.getItemElements();
    };
    AppDragGrid.prototype.beforeDestroy = function () {
        this.pckry.off('dragItemPositioned', this.onChangeDragItem);
        this.pckry.destroy();
    };
    AppDragGrid.prototype.mounted = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.initializeGrid();
                return [2 /*return*/];
            });
        });
    };
    AppDragGrid.prototype.onChangeDisableDrag = function (value) {
        if (value)
            this.disableDragGrid();
        else
            this.enableDragGrid();
    };
    __decorate([
        Prop()
    ], AppDragGrid.prototype, "columnWidth", void 0);
    __decorate([
        Prop()
    ], AppDragGrid.prototype, "rowHeight", void 0);
    __decorate([
        Prop()
    ], AppDragGrid.prototype, "gutter", void 0);
    __decorate([
        Prop()
    ], AppDragGrid.prototype, "horizontal", void 0);
    __decorate([
        Prop({ default: true })
    ], AppDragGrid.prototype, "forceItemsHorizontal", void 0);
    __decorate([
        Prop({ default: false })
    ], AppDragGrid.prototype, "disableDrag", void 0);
    __decorate([
        Emit('onChange')
    ], AppDragGrid.prototype, "onChange", null);
    __decorate([
        Watch('disableDrag')
    ], AppDragGrid.prototype, "onChangeDisableDrag", null);
    AppDragGrid = __decorate([
        Component({ components: {} })
    ], AppDragGrid);
    return AppDragGrid;
}(Vue));
export default AppDragGrid;

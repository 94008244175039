var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import { getCountryCallingCode, AsYouType } from 'libphonenumber-js';
import { getData } from 'country-list';
import AppSelectSingle from './Select/AppSelectSingle.vue';
import AppTextInput from './AppTextInput.vue';
import { formatPhoneNumber, getCountryFromPhoneNumber } from '../../helpers';
var AppPhoneInput = /** @class */ (function (_super) {
    __extends(AppPhoneInput, _super);
    function AppPhoneInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.focused = false;
        _this.coutryCallingCode = null;
        _this.internalCountryCodeNumber = _this.countryCodeNumber || null;
        return _this;
    }
    Object.defineProperty(AppPhoneInput.prototype, "countries", {
        get: function () {
            return getData().map(function (_a) {
                var name = _a.name, code = _a.code;
                return ({ label: name, code: code });
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppPhoneInput.prototype, "countryCodeFormatted", {
        get: function () {
            return this.internalCountryCodeNumber ? "+".concat(this.internalCountryCodeNumber) : null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppPhoneInput.prototype, "formattedPhoneNumber", {
        get: function () {
            return formatPhoneNumber(this.value, this.coutryCallingCode, this.useCountryCode);
        },
        enumerable: false,
        configurable: true
    });
    AppPhoneInput.prototype.onChangeCountry = function (_a) {
        var code = _a.code;
        this.coutryCallingCode = code;
        this.internalCountryCodeNumber = Number(getCountryCallingCode(code));
        this.onChangePhone();
        return Number(getCountryCallingCode(code)) || null;
    };
    AppPhoneInput.prototype.onChangePhone = function (phoneNumberInput) {
        if (phoneNumberInput === void 0) { phoneNumberInput = this.value; }
        var countryCode = this.useCountryCode ? this.coutryCallingCode : undefined;
        var asYoutype = new AsYouType(countryCode);
        var format = asYoutype.input(String(phoneNumberInput));
        this.onChange({ target: { value: format } });
        this.isValidEmit(phoneNumberInput, countryCode);
    };
    AppPhoneInput.prototype.isValidEmit = function (phoneNumberInput, countryCode) {
        var asYouTypeValidCheck = new AsYouType(countryCode);
        asYouTypeValidCheck.input("".concat(this.countryCodeFormatted, " ").concat(phoneNumberInput));
        return asYouTypeValidCheck.isValid() || asYouTypeValidCheck.isPossible();
    };
    AppPhoneInput.prototype.onChange = function (e) {
        return e.target.value;
    };
    AppPhoneInput.prototype.onEnterPress = function () {
        return this.value;
    };
    AppPhoneInput.prototype.onFocus = function () {
        this.focused = true;
        return;
    };
    AppPhoneInput.prototype.onLeave = function () {
        this.focused = false;
        return;
    };
    AppPhoneInput.prototype.created = function () {
        if ((!this.countryCodeNumber && this.useCountryCode) || !getCountryFromPhoneNumber(this.countryCodeNumber))
            return this.onChangeCountry({ code: 'US' });
        if (this.countryCodeNumber && this.useCountryCode && getCountryFromPhoneNumber(this.countryCodeNumber))
            return this.onChangeCountry({ code: getCountryFromPhoneNumber(this.countryCodeNumber) });
        return this.onChangeCountryCodeNumber();
    };
    AppPhoneInput.prototype.onChangeCountryCodeNumber = function () {
        this.internalCountryCodeNumber = this.countryCodeNumber;
    };
    __decorate([
        Prop()
    ], AppPhoneInput.prototype, "countryCodeNumber", void 0);
    __decorate([
        Prop({ default: '' })
    ], AppPhoneInput.prototype, "value", void 0);
    __decorate([
        Prop()
    ], AppPhoneInput.prototype, "loading", void 0);
    __decorate([
        Prop()
    ], AppPhoneInput.prototype, "disabled", void 0);
    __decorate([
        Prop({ default: function () { return null; } })
    ], AppPhoneInput.prototype, "label", void 0);
    __decorate([
        Prop({ default: function () { return null; } })
    ], AppPhoneInput.prototype, "placeholder", void 0);
    __decorate([
        Prop({ default: true })
    ], AppPhoneInput.prototype, "useCountryCode", void 0);
    __decorate([
        Emit('onChangeCountryCodeNumber')
    ], AppPhoneInput.prototype, "onChangeCountry", null);
    __decorate([
        Emit('isValid')
    ], AppPhoneInput.prototype, "isValidEmit", null);
    __decorate([
        Emit('input')
    ], AppPhoneInput.prototype, "onChange", null);
    __decorate([
        Emit('onEnterPress')
    ], AppPhoneInput.prototype, "onEnterPress", null);
    __decorate([
        Emit('onFocus')
    ], AppPhoneInput.prototype, "onFocus", null);
    __decorate([
        Emit('onLeave')
    ], AppPhoneInput.prototype, "onLeave", null);
    __decorate([
        Watch('countryCodeNumber')
    ], AppPhoneInput.prototype, "onChangeCountryCodeNumber", null);
    AppPhoneInput = __decorate([
        Component({ components: { AppSelectSingle: AppSelectSingle, AppTextInput: AppTextInput } })
    ], AppPhoneInput);
    return AppPhoneInput;
}(Vue));
export default AppPhoneInput;

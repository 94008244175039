var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import Muuri from 'muuri';
import { classIsFoundInParent, getDifferencesInArray, removeItemFromArray, timeout } from '../../helpers';
import isEqual from 'lodash/isEqual';
import { DATA_ITEM_KEY, extractDataIdFromGridItem, extractDataIndexFromGridItem, itemOrderChanged } from './helpers';
import { defaultMuuriConfig, disableClickBubbleClass, disableDragClass } from './config';
var AppMuuriGrid = /** @class */ (function (_super) {
    __extends(AppMuuriGrid, _super);
    function AppMuuriGrid() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.grid = null;
        _this.oldInternalItems = [];
        _this.internalItems = [];
        _this.cachedAppGridKey = '';
        _this.disableDragClass = disableDragClass;
        _this.showEventFired = false;
        return _this;
    }
    AppMuuriGrid.prototype.hasGridChanged = function () {
        var _this = this;
        var ids = this.getMurriIds();
        var appMuuriGridItems = ids.map(function (mappedId) { return _this.findItemById(mappedId); });
        return { items: appMuuriGridItems, hasChanged: !isEqual(appMuuriGridItems, this.oldInternalItems) };
    };
    AppMuuriGrid.prototype.getItemStyles = function (item) {
        return {
            width: "".concat(item.width, "px"),
            height: "".concat(item.height, "px"),
        };
    };
    AppMuuriGrid.prototype.refreshGrid = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_b) {
                (_a = this.grid) === null || _a === void 0 ? void 0 : _a.refreshItems().layout(false, function (items, hasLayoutChanged) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        // If hasLayoutChanged is `true` it means that there has been another layout
                        // call before this layout had time to finish positioning all the items.
                        this.setGridId();
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    AppMuuriGrid.prototype.setGridId = function () {
        this.cachedAppGridKey = this.gridId;
    };
    AppMuuriGrid.prototype.registerEvents = function () {
        var _this = this;
        var grid = this.grid;
        grid.on('dragStart', function (item, event) { return _this.$emit('dragStart', { item: item, event: event }); });
        grid.on('dragEnd', function (item, event) { return _this.dragEndHandler(item, event); });
        grid.on('showStart', function (items) { return _this.$emit('showStart', items); });
        grid.on('showEnd', function (items) {
            _this.$emit('showEnd', items);
            _this.showEventFired = true;
        });
        grid.on('layoutStart', function (items) { return _this.$emit('layoutStart', items); });
        grid.on('layoutEnd', function (items) { return _this.$emit('layoutEnd', items); });
        grid.on('destroy', function () { return _this.$emit('destroy'); });
    };
    AppMuuriGrid.prototype.initGrid = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.grid = new Muuri('.grid', __assign(__assign({}, defaultMuuriConfig), { dragSortPredicate: function (item) {
                                var result = Muuri.ItemDrag.defaultSortPredicate(item);
                                var itemId = _this.getMurriIds()[result === null || result === void 0 ? void 0 : result.index];
                                var matchingAppMurriItem = _this.findItemById(itemId);
                                return (matchingAppMurriItem === null || matchingAppMurriItem === void 0 ? void 0 : matchingAppMurriItem.preventFromBeingSorted) ? false : result;
                            } }));
                        return [4 /*yield*/, this.$nextTick()];
                    case 1:
                        _a.sent();
                        this.registerEvents();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppMuuriGrid.prototype.reinitGrid = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.removeItems(this.internalItems);
                        return [4 /*yield*/, this.$nextTick()];
                    case 1:
                        _a.sent();
                        this.addItems(this.items);
                        return [4 /*yield*/, this.$nextTick()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.refreshGrid()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppMuuriGrid.prototype.sortByItemsByIndexInDOM = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.refreshSortData()];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, ((_a = this.grid) === null || _a === void 0 ? void 0 : _a.sort(function (itemA, itemB) {
                                var aId = parseInt(extractDataIndexFromGridItem(itemA));
                                var bId = parseInt(extractDataIndexFromGridItem(itemB));
                                return aId - bId;
                            }))];
                    case 2:
                        _b.sent();
                        return [4 /*yield*/, this.$nextTick()];
                    case 3:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppMuuriGrid.prototype.refreshSortData = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, ((_a = this.grid) === null || _a === void 0 ? void 0 : _a.refreshSortData())];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.$nextTick()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    //Makes DOM match Murri
    AppMuuriGrid.prototype.synchronizeGrid = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, ((_a = this.grid) === null || _a === void 0 ? void 0 : _a.synchronize())];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.$nextTick()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppMuuriGrid.prototype.dragEndHandler = function (item, event) {
        if (event.distance > this.dragClickDistance)
            this.onChangeGridOrder();
        else
            this.onClickItem(item, event.srcEvent);
    };
    AppMuuriGrid.prototype.getMurriIds = function () {
        return this.getMuuriItems().map(function (i) { return Number(extractDataIdFromGridItem(i)); });
    };
    AppMuuriGrid.prototype.getMuuriItems = function () {
        return this.grid.getItems();
    };
    AppMuuriGrid.prototype.getMatchingGridItem = function (id) {
        return this.getMuuriItems().find(function (gridItem) { return extractDataIdFromGridItem(gridItem) == id; });
    };
    AppMuuriGrid.prototype.getMatchingAppMurriGridItem = function (gridItem) {
        return this.findItemById(extractDataIdFromGridItem(gridItem));
    };
    AppMuuriGrid.prototype.findItemById = function (itemId) {
        return this.items.find(function (_a) {
            var id = _a.id;
            return id == Number(itemId);
        });
    };
    AppMuuriGrid.prototype.removeItems = function (items) {
        var _this = this;
        var _a;
        var gridItemToRemove = items
            .map(function (item) { return _this.getMatchingGridItem(item.id); })
            .filter(function (gridItem) { return gridItem; });
        items.forEach(function (item) { return (_this.internalItems = removeItemFromArray(_this.internalItems, item)); });
        (_a = this.grid) === null || _a === void 0 ? void 0 : _a.remove(gridItemToRemove);
    };
    AppMuuriGrid.prototype.removeDragAbility = function (items) {
        var _this = this;
        var _a;
        var gridItem = items.map(function (item) { return _this.getMatchingGridItem(item.id); }).filter(function (gridItem) { return gridItem; });
        (_a = this.grid) === null || _a === void 0 ? void 0 : _a.remove(gridItem);
    };
    AppMuuriGrid.prototype.addDragAbility = function (items) {
        var _this = this;
        items.forEach(function (item) {
            var _a;
            var element = _this.$el.querySelector("[".concat(DATA_ITEM_KEY, "=\"").concat(item.id, "\"]"));
            (_a = _this.grid) === null || _a === void 0 ? void 0 : _a.add(element, {
                layout: false,
                active: false,
            });
        });
    };
    AppMuuriGrid.prototype.addItems = function (items) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.internalItems = __spreadArray(__spreadArray([], this.internalItems, true), items, true);
                        return [4 /*yield*/, this.$nextTick()];
                    case 1:
                        _b.sent();
                        items.forEach(function (item) {
                            var _a;
                            var element = _this.$el.querySelector("[".concat(DATA_ITEM_KEY, "=\"").concat(item.id, "\"]"));
                            element &&
                                ((_a = _this.grid) === null || _a === void 0 ? void 0 : _a.add(element, {
                                    layout: false,
                                    active: false,
                                }));
                        });
                        (_a = this.grid) === null || _a === void 0 ? void 0 : _a.filter(function () { return true; });
                        return [2 /*return*/];
                }
            });
        });
    };
    AppMuuriGrid.prototype.onClickItem = function (item, event) {
        var doNotSendEmit = classIsFoundInParent(event.path, disableClickBubbleClass);
        !doNotSendEmit &&
            this.$emit('onClickItem', { event: event, item: this.getMatchingAppMurriGridItem(item) });
    };
    AppMuuriGrid.prototype.onChangeGridOrder = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, hasChanged, items;
            return __generator(this, function (_b) {
                _a = this.hasGridChanged(), hasChanged = _a.hasChanged, items = _a.items;
                if (hasChanged) {
                    this.$emit('onChangeGridOrder', items);
                    this.oldInternalItems = items;
                }
                return [2 /*return*/];
            });
        });
    };
    AppMuuriGrid.prototype.created = function () {
        this.internalItems = __spreadArray([], this.items, true);
    };
    AppMuuriGrid.prototype.mounted = function () {
        this.internalItems.length && this.initGrid();
    };
    AppMuuriGrid.prototype.beforeDestroy = function () {
        var _a;
        // Clean up the observer and event listener
        (_a = this.grid) === null || _a === void 0 ? void 0 : _a.destroy();
    };
    AppMuuriGrid.prototype.onItemsChange = function (newItems, oldItems) {
        if (oldItems === void 0) { oldItems = []; }
        return __awaiter(this, void 0, void 0, function () {
            var gridReInit, changed, differeneInArray;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.grid) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.initGrid()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        gridReInit = !!this.grid && this.gridId !== this.cachedAppGridKey;
                        if (!gridReInit) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.reinitGrid()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        changed = this.autoSortItemsOnChange &&
                            itemOrderChanged(this.items.map(function (_a) {
                                var id = _a.id;
                                return Number(id);
                            }), this.getMurriIds());
                        if (!(changed && this.autoSortItemsOnChange)) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.sortByItemsByIndexInDOM()];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6:
                        differeneInArray = getDifferencesInArray(newItems, oldItems);
                        //new items were found
                        if (newItems.length > oldItems.length && !gridReInit)
                            this.addItems(differeneInArray);
                        //less items were found
                        if (newItems.length < oldItems.length && !gridReInit)
                            this.removeItems(differeneInArray);
                        //no change
                        if (newItems.length === oldItems.length && !gridReInit)
                            this.internalItems = this.items;
                        return [4 /*yield*/, timeout(0)];
                    case 7:
                        _a.sent();
                        if (!gridReInit)
                            this.refreshGrid();
                        return [2 /*return*/];
                }
            });
        });
    };
    AppMuuriGrid.prototype.onInternalItemsChange = function (newItems, oldItems) {
        this.oldInternalItems = newItems;
    };
    __decorate([
        Prop({ default: function () { return []; } })
    ], AppMuuriGrid.prototype, "items", void 0);
    __decorate([
        Prop()
    ], AppMuuriGrid.prototype, "gridId", void 0);
    __decorate([
        Prop({ default: false })
    ], AppMuuriGrid.prototype, "autoSortItemsOnChange", void 0);
    __decorate([
        Prop({ default: 5 })
    ], AppMuuriGrid.prototype, "dragClickDistance", void 0);
    __decorate([
        Emit('onGridRefresh')
    ], AppMuuriGrid.prototype, "refreshGrid", null);
    __decorate([
        Watch('items')
    ], AppMuuriGrid.prototype, "onItemsChange", null);
    __decorate([
        Watch('internalItems')
    ], AppMuuriGrid.prototype, "onInternalItemsChange", null);
    AppMuuriGrid = __decorate([
        Component({ components: {} })
    ], AppMuuriGrid);
    return AppMuuriGrid;
}(Vue));
export default AppMuuriGrid;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import AppButton from '../Button/AppButton.vue';
import AppIcon from '../Icon/AppIcon.vue';
var AppModal = /** @class */ (function (_super) {
    __extends(AppModal, _super);
    function AppModal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(AppModal.prototype, "hasHeaderSlot", {
        get: function () {
            return !!this.$slots.header;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppModal.prototype, "hasFooterSlot", {
        get: function () {
            return !!this.$slots.footer;
        },
        enumerable: false,
        configurable: true
    });
    AppModal.prototype.onClose = function () {
        return;
    };
    AppModal.prototype.onSubmit = function () {
        return;
    };
    __decorate([
        Prop()
    ], AppModal.prototype, "isOpen", void 0);
    __decorate([
        Prop()
    ], AppModal.prototype, "title", void 0);
    __decorate([
        Prop()
    ], AppModal.prototype, "loadingSubmit", void 0);
    __decorate([
        Prop()
    ], AppModal.prototype, "disableSubmit", void 0);
    __decorate([
        Prop({ default: 'Submit' })
    ], AppModal.prototype, "submitButtonText", void 0);
    __decorate([
        Emit('onClose')
    ], AppModal.prototype, "onClose", null);
    __decorate([
        Emit('onSubmit')
    ], AppModal.prototype, "onSubmit", null);
    AppModal = __decorate([
        Component({ components: { AppButton: AppButton, AppIcon: AppIcon } })
    ], AppModal);
    return AppModal;
}(Vue));
export default AppModal;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
var AppSliderInput = /** @class */ (function (_super) {
    __extends(AppSliderInput, _super);
    function AppSliderInput() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AppSliderInput.prototype.onChange = function (n) {
        this.onDragEnd(n);
        return n;
    };
    AppSliderInput.prototype.onDragStart = function (n) {
        return n;
    };
    AppSliderInput.prototype.onDragEnd = function (n) {
        //@ts-ignore
        return this.$refs.slider.getValue();
    };
    __decorate([
        Prop({ default: false })
    ], AppSliderInput.prototype, "contained", void 0);
    __decorate([
        Prop()
    ], AppSliderInput.prototype, "label", void 0);
    __decorate([
        Prop()
    ], AppSliderInput.prototype, "min", void 0);
    __decorate([
        Prop()
    ], AppSliderInput.prototype, "max", void 0);
    __decorate([
        Prop()
    ], AppSliderInput.prototype, "background", void 0);
    __decorate([
        Prop()
    ], AppSliderInput.prototype, "step", void 0);
    __decorate([
        Prop()
    ], AppSliderInput.prototype, "value", void 0);
    __decorate([
        Prop({ default: 22 })
    ], AppSliderInput.prototype, "dotSize", void 0);
    __decorate([
        Prop()
    ], AppSliderInput.prototype, "lazy", void 0);
    __decorate([
        Prop()
    ], AppSliderInput.prototype, "disabled", void 0);
    __decorate([
        Prop()
    ], AppSliderInput.prototype, "interval", void 0);
    __decorate([
        Prop({ default: 14 })
    ], AppSliderInput.prototype, "height", void 0);
    __decorate([
        Prop()
    ], AppSliderInput.prototype, "tooltipFormatter", void 0);
    __decorate([
        Prop()
    ], AppSliderInput.prototype, "marks", void 0);
    __decorate([
        Prop()
    ], AppSliderInput.prototype, "tooltipPlacement", void 0);
    __decorate([
        Prop()
    ], AppSliderInput.prototype, "tooltip", void 0);
    __decorate([
        Prop()
    ], AppSliderInput.prototype, "dotOptions", void 0);
    __decorate([
        Prop()
    ], AppSliderInput.prototype, "hideLabel", void 0);
    __decorate([
        Prop()
    ], AppSliderInput.prototype, "railStyle", void 0);
    __decorate([
        Prop()
    ], AppSliderInput.prototype, "dotStyle", void 0);
    __decorate([
        Prop()
    ], AppSliderInput.prototype, "processStyle", void 0);
    __decorate([
        Prop()
    ], AppSliderInput.prototype, "tooltipStyle", void 0);
    __decorate([
        Emit('input')
    ], AppSliderInput.prototype, "onChange", null);
    __decorate([
        Emit('onDragStart')
    ], AppSliderInput.prototype, "onDragStart", null);
    __decorate([
        Emit('onDragEnd')
    ], AppSliderInput.prototype, "onDragEnd", null);
    AppSliderInput = __decorate([
        Component({ components: { VueSlider: VueSlider } })
    ], AppSliderInput);
    return AppSliderInput;
}(Vue));
export default AppSliderInput;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Vue, Component, Prop } from 'vue-property-decorator';
import { AlertBoxTypes } from './interfaces';
import { AppIcon } from '../Icon';
var AppAlertBox = /** @class */ (function (_super) {
    __extends(AppAlertBox, _super);
    function AppAlertBox() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(AppAlertBox.prototype, "dynamicClass", {
        get: function () {
            var _a;
            return _a = {},
                _a["app-alert-box__".concat(this.type)] = true,
                _a;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AppAlertBox.prototype, "hasFooter", {
        get: function () {
            return this.$slots.footer;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        Prop()
    ], AppAlertBox.prototype, "title", void 0);
    __decorate([
        Prop()
    ], AppAlertBox.prototype, "text", void 0);
    __decorate([
        Prop()
    ], AppAlertBox.prototype, "appendedIcon", void 0);
    __decorate([
        Prop()
    ], AppAlertBox.prototype, "prependedIcon", void 0);
    __decorate([
        Prop({ default: AlertBoxTypes.error })
    ], AppAlertBox.prototype, "type", void 0);
    AppAlertBox = __decorate([
        Component({ components: { AppIcon: AppIcon } })
    ], AppAlertBox);
    return AppAlertBox;
}(Vue));
export default AppAlertBox;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { Cropper } from 'vue-advanced-cropper';
import AppBasicSpinnerLoader from '../Loader/AppBasicSpinnerLoader.vue';
import { AppButton } from '../Button';
import { AppIcon } from '../Icon';
import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/theme.classic.css';
import { nextInArray } from '../../helpers';
import AppSliderInput from '../Inputs/AppSliderInput.vue';
import last from 'lodash/last';
var AppCropImage = /** @class */ (function (_super) {
    __extends(AppCropImage, _super);
    function AppCropImage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.rotateAmount = 0;
        _this.loaded = false;
        _this.coordinates = {
            height: 0,
            width: 0,
            top: 0,
            left: 0,
        };
        _this.internalImage = {
            src: '',
            width: 0,
            height: 0,
            transforms: {
                rotate: 0,
                flip: {
                    horizontal: false,
                    vertical: false,
                },
                translateX: 0,
                translateY: 0,
                scaleX: 0,
                scaleY: 0,
            },
        };
        _this.internalCanvas = null;
        _this.internalVisibleArea = {
            left: 0,
            top: 0,
            width: 0,
            height: 0,
        };
        //Rotate Variables
        _this.parentElementCoordinates = { top: 0, left: 0, width: 0, height: 0 };
        return _this;
    }
    Object.defineProperty(AppCropImage.prototype, "cropperStyle", {
        get: function () {
            return {
                height: "".concat(this.height - 85, "px"),
            };
        },
        enumerable: false,
        configurable: true
    });
    AppCropImage.prototype.onDragSlider = function (n) {
        this.onRotate(this.determineAmountToRotate(n));
    };
    AppCropImage.prototype.determineAmountToRotate = function (wantToRotate) {
        //@ts-ignore
        var currentRotateAmount = this.$refs.cropper.getResult().imageTransforms.rotate;
        if (wantToRotate < 0) {
            var amountToRotate_1 = -Math.abs(currentRotateAmount - wantToRotate);
            var isMovingNegative = wantToRotate < currentRotateAmount;
            return isMovingNegative ? amountToRotate_1 : -amountToRotate_1;
        }
        var amountToRotate = Math.abs(currentRotateAmount - wantToRotate);
        var isMovingPositive = wantToRotate > currentRotateAmount;
        var isPositive = wantToRotate >= 0;
        if (!isPositive)
            amountToRotate = -amountToRotate;
        return isMovingPositive ? amountToRotate : -amountToRotate;
    };
    AppCropImage.prototype.onRotateClick = function () {
        var _this = this;
        var rotateRecord = [-90, 0, 90, 180];
        var nextCloset = rotateRecord.find(function (record) { return record > _this.rotateAmount; });
        var nextClosetIsSlider = nextCloset === this.rotateAmount;
        var dragPosition = nextInArray(rotateRecord, this.rotateAmount);
        if (last(rotateRecord) === nextCloset && nextClosetIsSlider)
            dragPosition = rotateRecord[2];
        if (dragPosition === this.rotateAmount)
            dragPosition = nextInArray(rotateRecord, this.rotateAmount);
        if (dragPosition === 0)
            dragPosition = rotateRecord[1];
        var rotateAmount = this.determineAmountToRotate(dragPosition);
        this.onRotate(rotateAmount);
        this.rotateAmount = dragPosition;
    };
    AppCropImage.prototype.onRotate = function (amount) {
        this.$refs.cropper.rotate(amount);
    };
    AppCropImage.prototype.onChange = function (_a) {
        var coordinates = _a.coordinates, canvas = _a.canvas, image = _a.image, visibleArea = _a.visibleArea;
        this.coordinates = coordinates;
        this.internalImage = image;
        this.internalCanvas = canvas;
        this.internalVisibleArea = visibleArea;
    };
    AppCropImage.prototype.applyBackgroundColorForSave = function (dataURL) {
        var _this = this;
        return new Promise(function (resolve) {
            var image = new Image();
            var canvas = _this.$refs.editor;
            image.src = dataURL;
            image.crossOrigin = 'anonymous';
            image.onload = function () {
                canvas.width = image.naturalWidth;
                canvas.height = image.naturalHeight;
                var context = canvas.getContext('2d');
                context.fillStyle = _this.backgroundFillColor;
                context.fillRect(0, 0, image.naturalWidth, image.naturalHeight);
                context.drawImage(image, 0, 0);
                resolve(canvas.toDataURL());
            };
        });
    };
    AppCropImage.prototype.onReady = function () {
        this.loaded = true;
        return;
    };
    AppCropImage.prototype.onError = function (err) {
        return err;
    };
    AppCropImage.prototype.onSave = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var dataURL;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        dataURL = (_a = this.internalCanvas) === null || _a === void 0 ? void 0 : _a.toDataURL('png', 1);
                        if (!(this.backgroundFillColor && this.rotateAmount)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.applyBackgroundColorForSave(dataURL)];
                    case 1:
                        dataURL = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/, __assign(__assign({}, this.coordinates), { canvas: this.internalCanvas, visibleArea: this.internalVisibleArea, image: this.internalImage, rotate: this.rotateAmount, dataURL: dataURL })];
                }
            });
        });
    };
    AppCropImage.prototype.onCancel = function () {
        return;
    };
    __decorate([
        Prop()
    ], AppCropImage.prototype, "imageUrl", void 0);
    __decorate([
        Prop({ default: 600 })
    ], AppCropImage.prototype, "height", void 0);
    __decorate([
        Prop({ default: 600 })
    ], AppCropImage.prototype, "width", void 0);
    __decorate([
        Prop({ default: function () { return 1 / 1; } })
    ], AppCropImage.prototype, "aspectRatio", void 0);
    __decorate([
        Prop({ default: true })
    ], AppCropImage.prototype, "showLoader", void 0);
    __decorate([
        Prop()
    ], AppCropImage.prototype, "loading", void 0);
    __decorate([
        Prop({ default: true })
    ], AppCropImage.prototype, "useRotate", void 0);
    __decorate([
        Prop()
    ], AppCropImage.prototype, "disableCancel", void 0);
    __decorate([
        Prop()
    ], AppCropImage.prototype, "disableSubmit", void 0);
    __decorate([
        Prop()
    ], AppCropImage.prototype, "applyStencilClasses", void 0);
    __decorate([
        Prop()
    ], AppCropImage.prototype, "backgroundFillColor", void 0);
    __decorate([
        Emit('onReady')
    ], AppCropImage.prototype, "onReady", null);
    __decorate([
        Emit('onError')
    ], AppCropImage.prototype, "onError", null);
    __decorate([
        Emit('onSaveCrop'),
        Emit('onSave')
    ], AppCropImage.prototype, "onSave", null);
    __decorate([
        Emit('onCancel')
    ], AppCropImage.prototype, "onCancel", null);
    AppCropImage = __decorate([
        Component({ components: { Cropper: Cropper, AppBasicSpinnerLoader: AppBasicSpinnerLoader, AppButton: AppButton, AppIcon: AppIcon, AppSliderInput: AppSliderInput } })
    ], AppCropImage);
    return AppCropImage;
}(Vue));
export default AppCropImage;
